import * as React from 'react';
import { ResourceCalendar } from './ResourceCalendar';
import { ResourceActionBar } from './ResourceActionBar';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { Checkbox } from '@fluentui/react-northstar/dist/es/components/Checkbox/Checkbox';
import { IWeekDays } from '../interfaces/IWeekDays';
import * as microsoftTeams from "@microsoft/teams-js";
import { AuthService } from '../services/AuthService';
import { AzureService } from '../services/AzureService';
import { IPlan } from '../interfaces/IPlan';
import { IStructurepart } from '../interfaces/IStructurepart';
import { IBlock } from '../interfaces/IBlock';
import { IStructure } from '../interfaces/IStructure';
import { IPlanContent } from '../interfaces/IPlanContent';
import ResourcePlanPanel from './ResourcePlanPanel';
import { Dropdown, DropdownProps } from '@fluentui/react-northstar/dist/es/components/Dropdown/Dropdown';
import { DropdownItemProps } from '@fluentui/react-northstar/dist/es/components/Dropdown/DropdownItem';
import { ShorthandCollection } from '@fluentui/react-northstar/dist/es/types';
import { Loader } from '@fluentui/react-northstar/dist/es/components/Loader/Loader';
import { Dialog } from '@fluentui/react-northstar/dist/es/components/Dialog/Dialog';

export interface IResourcePlanProps {
    chooseWeeks: number;
    blockableDays: IWeekDays;
}

export interface IResourcePlanStates {
    togglePanel: boolean;
    waiting: boolean;
    currentUserId: number;
    editMode: boolean;
    startDate: Date;
    messageBar: JSX.Element;
    dialogHiddenForUser: boolean;
    dialogHiddenForManager: boolean;
    dialogHiddenForProject: boolean;
    removingItem: IStructurepart;
    editResource: IStructurepart;
    removeAll: boolean;
    projectString: string;
    alldayBlock: boolean;
    highlight: boolean;
    isOneStep: boolean;
    showLogin: boolean;
    allPlans: IPlan[];
    planOptions: ShorthandCollection<DropdownItemProps, Record<string, {}>>;
    chosenPlan: IPlan;
    chosenDropdownOption: any;
    planContent: IPlanContent;
    selectedBlocker?: IStructurepart;
    selectedSubBlocker?: IStructurepart;
    clientType: microsoftTeams.HostClientType;
    slimView: boolean;
    loadingStatus: JSX.Element;
    theme: string;
    chosenBlockerOption: any;
    blockerOptions: ShorthandCollection<DropdownItemProps, Record<string, {}>>;
}

export const ResourcePlanContext = React.createContext(false);


export default class ResourcePlan extends React.Component<IResourcePlanProps, IResourcePlanStates> {

    constructor(props: IResourcePlanProps) {
        super(props);
        this.state = {
            messageBar: <></>,
            startDate: new Date(),
            editMode: false,
            currentUserId: null,
            togglePanel: false,
            waiting: true,
            dialogHiddenForUser: true,
            dialogHiddenForManager: true,
            dialogHiddenForProject: true,
            removingItem: undefined,
            editResource: undefined,
            removeAll: false,
            projectString: "",
            alldayBlock: false,
            highlight: false,
            isOneStep: false,
            showLogin: false,
            allPlans: [],
            chosenPlan: undefined,
            chosenDropdownOption: undefined,
            planOptions: [],
            planContent: {
                structure: {
                    blocker: [],
                    subBlocker: [],
                    resource: []
                },
                blocks: []
            },
            clientType: undefined,
            slimView: false,
            loadingStatus: <></>,
            theme: "default",
            chosenBlockerOption: undefined,
            blockerOptions: []
        };

        {
            let canvas = document.querySelectorAll(".CanvasControlToolbar");
            // @ts-ignore
            canvas.forEach(element => { element.style.zIndex = 3; });
        }
    }

    public componentDidMount() {
        microsoftTeams.initialize(() => {
            microsoftTeams.getContext((context) => {
                let authService: AuthService = new AuthService();
                authService.setClientType(context.hostClientType);
                this.setState({ clientType: context.hostClientType, theme: context.theme });
                authService.tryLogin(this._getPlansAfterLogin, (error: string) => {
                    this.setState({ ...this.state, showLogin: true });
                }, context.loginHint);
            });
        });
    }

    public render(): React.ReactElement<{}> {



        return (
            <div className={"ressourcenplanung"}>
                <div>
                    <ResourcePlanPanel
                        chosenPlan={this.state.chosenPlan}
                        chosenDropdownOption={this.state.chosenDropdownOption}
                        onPlanChosen={this._onPlanChosen}
                        planItems={this.state.planOptions}
                        setMessage={this._setMessage}
                        refreshPlans={this._refreshPlanData}
                        resources={this.state.planContent.structure.resource}
                        loadingStatus={this.state.loadingStatus}
                    />
                </div>
                {this.state.messageBar}
                {
                    this.state.chosenPlan != undefined && (<>
                        <ResourceActionBar
                            setHighlight={this._setHighlight}
                            setSelectedBlocker={this._setCurrentBlocker}
                            alldayBlock={this._setAlldayBlock}
                            editResource={this.state.editResource}
                            clearEditItem={this._clearEditItem}
                            refreshCalendar={this._refreshCalendar}
                            planContent={this.state.planContent}
                            waiting={this.state.waiting}
                            onSubBlockerSelected={this._setCurrentSubBlocker}
                            messageBar={this._setMessage}
                            setIsOneStep={this._setIsOneStep}
                            removeBlocker={this._queryToRemove}
                            refreshStructure={this._refreshStructureData}
                            chosenPlan={this.state.chosenPlan}
                            selectedBlocker={this.state.selectedBlocker}
                            chosenBlockerOption={this.state.chosenBlockerOption}
                            blockerOptions={this.state.blockerOptions}
                            setSlimView={this._setSlimView}
                            slimView={this.state.slimView}
                            onEditMode={this._setEditMode} />
                        <ResourceCalendar
                            blockableDays={this.props.blockableDays}
                            highlight={this.state.highlight}
                            alldayBlock={this.state.alldayBlock}
                            slimView={this.state.slimView}
                            refreshCalendar={this._refreshCalendar}
                            messageBar={this._setMessage}
                            planContent={this.state.planContent}
                            waitingForCall={this.state.waiting}
                            choosenWeeks={this.props.chooseWeeks}
                            currentUserId={this.state.currentUserId}
                            selectedBlocker={this.state.selectedBlocker}
                            selectedSubBlocker={this.state.selectedSubBlocker}
                            editMode={this.state.editMode}
                            clientType={this.state.clientType}
                            startDate={this.state.startDate}
                            removeStructurepart={this._queryToRemove}
                            refreshBlockData={this._refreshBlockData}
                            theme={this.state.theme}
                            chosenPlan={this.state.chosenPlan}
                            editResource={this._editItem} />
                        <Dialog
                            open={!this.state.dialogHiddenForUser}
                            cancelButton="Cancel"
                            confirmButton="Remove"
                            onCancel={this._closeDialog}
                            onConfirm={() => this._deleteStructurepart(this.state.removingItem)}
                            header={'Removing resource'}
                            content={
                                <div className={"removingDialogText"}>
                                    <span>{"You're going to remove this " + this.state.chosenPlan.resourceLabel + ": "} </span> <br />
                                    <strong>{this.state.removingItem ? this.state.removingItem.name : ""}</strong>

                                    <Checkbox
                                        className={"checkBoxRemoving"}
                                        label="Remove all associated assignments?"
                                        checked={this.state.removeAll}
                                        onChange={(ev, props) => { this.setState({ removeAll: props.checked }); }} />

                                    <span style={{ fontWeight: "bold", marginTop: "20px" }}>{"Are you sure?"}</span>
                                </div>
                            }
                        />

                        <Dialog
                            open={!this.state.dialogHiddenForProject}
                            header={'Removing project'}
                            cancelButton="Cancel"
                            confirmButton="Remove"
                            onCancel={this._closeDialog}
                            onConfirm={() => this._deleteStructurepart(this.state.removingItem)}
                            content={
                                <div className={"removingDialogText"}>
                                    <span>{"You're going to remove this " + this.state.chosenPlan.subBlockerLabel + ": "} </span> <br />
                                    <strong>{this.state.removingItem ? this.state.removingItem.name : ""}</strong><br />
                                    <span style={{ fontWeight: "bold", marginTop: "20px" }}>{"Are you sure?"}</span>
                                </div>
                            }
                        />

                        <Dialog
                            open={!this.state.dialogHiddenForManager}
                            confirmButton="Remove"
                            cancelButton="Cancel"
                            closeOnOutsideClick={false}
                            onConfirm={() => this._deleteStructurepart(this.state.removingItem)}
                            onCancel={this._closeDialog}
                            content={
                                <div className={"removingDialogText"}>
                                    <span>{"You're going to remove this " + this.state.chosenPlan.blockerLabel + ": "} </span> <br />
                                    <strong>{this.state.removingItem ? this.state.removingItem.name : ""}</strong><br />
                                    <span style={{ fontWeight: "bold", marginTop: "20px" }}>{"Are you sure?"}</span>
                                </div>
                            }
                            header="Removing blocker"
                        />
                    </>)
                }

                <Dialog
                    open={this.state.showLogin}
                    header='Login required'
                    confirmButton="Login"
                    onConfirm={() => {
                        let authService: AuthService = new AuthService();
                        authService.login(this._getPlansAfterLogin, (error: string) => {
                            console.log(error);
                        })
                    }}
                    content='You need to login to get access.'
                />
            </div>
        );
    }

    private _setSlimView = (value: boolean) => {
        this.setState({ slimView: value });
    }

    private _setIsOneStep = (value: boolean): void => {
        this.setState({ isOneStep: value });
    }

    private _setHighlight = (checked: boolean): void => {
        this.setState({ highlight: checked });
    }

    private _editItem = (user: IStructurepart): void => {
        this.setState({ editResource: user });
    }

    private _clearEditItem = (): void => {
        this.setState({ editResource: undefined });
    }

    private _refreshCalendar = (): void => {
        this.setState({ messageBar: <></> });
    }

    private _setMessage = (message: JSX.Element, ms?: number): void => {
        this.setState({ messageBar: message });
        if (ms) {
            setTimeout(() => {
                this.setState({ messageBar: <></> });
            }, ms);
        }
    }

    private _setCurrentSubBlocker = (subBlocker: IStructurepart): void => {
        this.setState({ selectedSubBlocker: subBlocker });
    }

    private _setCurrentBlocker = (blocker: IStructurepart, option: any): void => {
        if(blocker != null && option != null){
            this.setState({ selectedBlocker: blocker, chosenBlockerOption: option });
        }
        else {
            this.setState({selectedBlocker: null, chosenBlockerOption: undefined});
        }

    }

    private _setAlldayBlock = (value: boolean): void => {
        this.setState({ alldayBlock: value });
    }

    private _setEditMode = (checked: boolean): void => {
        this.setState({ editMode: checked });
    }

    private _queryToRemove = async (item: IStructurepart): Promise<void> => {
        switch (item.type) {
            case 1: {
                this.setState({ removingItem: item, dialogHiddenForUser: false });
                break;
            }
            case 3: {
                this.setState({ removingItem: item, dialogHiddenForProject: false });
                break;
            }
            case 2: {
                await this._checkForAssociatedProjects(item.id).then((projects: string) => {
                    if (!projects) {
                        this.setState({ removingItem: item, dialogHiddenForManager: false });
                    } else {
                        this._setMessage(<MessageBar messageBarType={MessageBarType.warning} onDismiss={() => this.setState({ messageBar: <></> })} dismissButtonAriaLabel="Close">You can't delete this project manager as long as he is associated to following projects: {projects}{' '}</MessageBar>);
                    }
                });
                break;
            }
            default: {
                throw "Something went wrong in 'Ressourcenplanung.ts' -> _queryToRemove ";
            }
        }
    }

    private _checkForAssociatedProjects = async (id: string): Promise<string> => {
        let subBlockers: string[] = [];
        for (let i = 0; i < this.state.planContent.structure.blocker.length; i++) {
            if (this.state.planContent.structure.blocker[i].id == id) {
                for (let j = 0; j < this.state.planContent.structure.subBlocker.length; j++) {
                    if (this.state.planContent.structure.subBlocker[j].parent == this.state.planContent.structure.blocker[i].id) {
                        subBlockers.push(this.state.planContent.structure.subBlocker[j].name);
                    }
                }
                break;
            }
        }
        return subBlockers.join(", ");
    }

    private _deleteStructurepart = async (deleteItem: IStructurepart): Promise<void> => {
        AzureService.deleteStructure(deleteItem).then(() => {
            this._closeDialog();
            this._refreshBlockData();
            this._refreshStructureData();
        }).catch((error) => {
            let messageBar = <MessageBar
                messageBarType={MessageBarType.error}
                onDismiss={() => this.setState({ messageBar: <></> })}
                dismissButtonAriaLabel="Close">
                An error occured while loading.
            </MessageBar>;

            this.setState({ waiting: false, loadingStatus: <></>, messageBar: messageBar })
        });
    }

    private _closeDialog = (): void => {
        this.setState({ dialogHiddenForUser: true, removeAll: false, dialogHiddenForManager: true, dialogHiddenForProject: true });
    }

    private _getPlansAfterLogin = (token: string) => {
        AzureService.setToken(token);
        this.setState({ showLogin: false, loadingStatus: <Loader size="smallest" /> });

        AzureService.getAllPlans().then((plans: IPlan[]) => {
            let planOptions = plans.map<any>((currentPlan) => {
                let option: any = {
                    key: currentPlan.id,
                    header: currentPlan.name,
                }
                return option;
            });
            let messageBar = <></>;

            if (plans.length === 0) {
                messageBar = <MessageBar messageBarType={MessageBarType.info} onDismiss={() => this.setState({ messageBar: <></> })} dismissButtonAriaLabel="Close">There are no plans available right now.</MessageBar>;
            }

            this.setState({ allPlans: plans, planOptions: planOptions, waiting: false, loadingStatus: <></>, messageBar: messageBar });
        }).catch((error) => {
            let messageBar = <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ messageBar: <></> })} dismissButtonAriaLabel="Close">An error occured while loading.</MessageBar>;
            this.setState({ waiting: false, loadingStatus: <></>, messageBar: messageBar })
        });
    }

    private _onPlanChosen = (event, props: DropdownProps) => {
        let chosenPlan: IPlan = this.state.allPlans.find((value, index, obj) => {
            let newVal: any = props.value;
            if (value.id == newVal.key) {
                return true;
            } else {
                return false;
            }
        });

        AzureService.setPlanId(chosenPlan.id);
        this.setState({ chosenPlan: chosenPlan, chosenDropdownOption: props.value, isOneStep: chosenPlan.steps == 1 }, this._getPlanData);
    }

    private _getPlanData = () => {
        let promises: any[] = [
            AzureService.getStructure(),
            AzureService.getBlocks()
        ];

        Promise.all(promises).then((values) => {
            let structureparts: IStructurepart[] = values[0];
            let structure: IStructure = this._buildStructure(structureparts);
            let blockerOptions = this._generateBlockerOptions(structure.blocker);
            let block: IBlock[] = values[1];

            this.setState({ planContent: { structure: structure, blocks: block }, blockerOptions: blockerOptions });
        }).catch((error) => {
            let messageBar = <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ messageBar: <></> })} dismissButtonAriaLabel="Close">An error occured while loading.</MessageBar>;
            this.setState({ waiting: false, loadingStatus: <></>, messageBar: messageBar });
        });
    }

    private _refreshPlanData = (restoreChosenPlan: boolean) => {
        AzureService.getAllPlans().then((plans: IPlan[]) => {
            let refreshedChosenPlan = undefined;
            let chosenPlanOption = undefined;
            let planOptions = plans.map<any>((currentPlan) => {
                let option: any = {
                    key: currentPlan.id,
                    header: currentPlan.name
                }

                if (this.state.chosenPlan != undefined && currentPlan.id == this.state.chosenPlan.id) {
                    refreshedChosenPlan = currentPlan;
                    chosenPlanOption = option;
                }
                return option;
            });

            let messageBar = <></>;

            if (plans.length === 0) {
                messageBar = <MessageBar
                    messageBarType={MessageBarType.info}
                    onDismiss={() => this.setState({ messageBar: <></> })}
                    dismissButtonAriaLabel="Close">
                    There are no plans available right now.
                </MessageBar>;
            }

            this.setState({ allPlans: plans, planOptions: planOptions, waiting: false, messageBar: messageBar, chosenPlan: undefined, chosenDropdownOption: "" }, () => {
                if (restoreChosenPlan) {
                    this.setState({ chosenPlan: refreshedChosenPlan, chosenDropdownOption: chosenPlanOption });
                }
            });

        }).catch((error) => {
            console.log(error);
            let messageBar = <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ messageBar: <></> })} dismissButtonAriaLabel="Close">An error occured while loading.</MessageBar>
            this.setState({ waiting: false, loadingStatus: <></>, messageBar: messageBar })
        });
    }

    private _refreshBlockData = () => {
        AzureService.getBlocks().then((blocks: IBlock[]) => {
            let structure = this.state.planContent.structure;

            this.setState({ planContent: { structure: structure, blocks: blocks } });
        }).catch((error) => {
            let messageBar = <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ messageBar: <></> })} dismissButtonAriaLabel="Close">An error occured while loading.</MessageBar>
            this.setState({ waiting: false, loadingStatus: <></>, messageBar: messageBar })
        });
    }

    private _refreshStructureData = () => {
        AzureService.getStructure().then((structureparts: IStructurepart[]) => {
            let blocks = this.state.planContent.blocks;
            let structure = this._buildStructure(structureparts);
            let chosenBlockerOption = undefined;
            let selectedBlocker = null;
            let sortedblockers = structure.blocker.sort((a: IStructurepart, b: IStructurepart) => {
                if (a.name === b.name) {
                    return 0;
                }
                else {
                    return (a.name < b.name) ? -1 : 1;
                }
            });

            let blockerOptions = sortedblockers.map((currentBlocker: IStructurepart) => {
                let currentItem: any = {
                    key: currentBlocker.id,
                    header: currentBlocker.name
                }

                if (this.state.selectedBlocker != undefined && currentBlocker.id == this.state.selectedBlocker.id) {
                    selectedBlocker = currentBlocker;
                    chosenBlockerOption = currentItem;
                }

                return currentItem;
            });

            this.setState({ planContent: { structure: structure, blocks: blocks }, blockerOptions:blockerOptions, chosenBlockerOption: undefined, selectedBlocker: null },()=>{
                this.setState({chosenBlockerOption: chosenBlockerOption, selectedBlocker: selectedBlocker});
            });
        }).catch((error) => {
            console.log(error);
            let messageBar = <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ messageBar: <></> })} dismissButtonAriaLabel="Close">An error occured while loading.</MessageBar>
            this.setState({ waiting: false, loadingStatus: <></>, messageBar: messageBar })
        });
    }

    private _generateBlockerOptions = (blockers: IStructurepart[]) => {
        let sortedblockers = blockers.sort((a: IStructurepart, b: IStructurepart) => {
            if (a.name === b.name) {
                return 0;
            }
            else {
                return (a.name < b.name) ? -1 : 1;
            }
        });

        let items: any[] = [];

        sortedblockers.forEach((currentBlocker: IStructurepart) => {

            let currentItem: any = {
                key: currentBlocker.id,
                header: currentBlocker.name
            }

            items.push(currentItem);

        });

        return items;
    }

    private _buildStructure = (structureparts: IStructurepart[]): IStructure => {
        let structure: IStructure = {
            blocker: [],
            subBlocker: [],
            resource: []
        };

        structureparts.forEach((value) => {
            switch (value.type) {
                case 1:
                    structure.resource.push(value);
                    break;
                case 2:
                    structure.blocker.push(value);
                    break;
                case 3:
                    structure.subBlocker.push(value);
                    break;
            }
        });

        return structure;
    }
}

ResourcePlan.contextType = ResourcePlanContext;