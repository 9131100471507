import * as React from 'react';
import { ResourceSlot } from './ResourceSlot';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { IWeek } from './ResourceCalendar';
import { IWeekDays } from '../interfaces/IWeekDays';
import { IStructurepart } from '../interfaces/IStructurepart';
import { IPlanContent } from '../interfaces/IPlanContent';
import { IBlock } from '../interfaces/IBlock';
import { ITimeSlot } from '../interfaces/ITimeSlot';
import { HostClientType } from '@microsoft/teams-js';
import { Menu } from '@fluentui/react-northstar/dist/es/components/Menu/Menu';
import { MoreIcon } from '@fluentui/react-icons-northstar/dist/es/components/MoreIcon';
import { EditIcon } from '@fluentui/react-icons-northstar/dist/es/components/EditIcon';
import { TrashCanIcon } from '@fluentui/react-icons-northstar/dist/es/components/TrashCanIcon';
import { menuAsToolbarBehavior } from '@fluentui/accessibility/dist/es/behaviors/Toolbar/menuAsToolbarBehavior';


export interface IResourceUserRowProps {
    highlight: boolean;
    refreshCalendar(): void;
    resource: IStructurepart;
    alldayBlock: boolean;
    arr_ddMM: IWeek[];
    currentUserId: number;
    editMode: boolean;
    selectedSubBlocker: IStructurepart;
    firstWeekDays: number;
    choosenWeeks: number;
    messageBar(messageBar: JSX.Element): void;
    errorMessage(message: JSX.Element): void;
    firstRow: boolean;
    removeStructurepart: Function;
    editResource: Function;
    selectedBlocker: IStructurepart;
    planContent: IPlanContent
    blockableDays: IWeekDays;
    refreshBlockData: Function;
    startDate: Date;
    clientType: HostClientType;
    slimView: boolean;
    theme: string;
}

export interface IResourceUserRowStates {
    userHighlight: { state: boolean, userID: string };
    showContextMenu: boolean;
}

export interface IMatchedBlock {
    Block: IBlock;
    Blocker: IStructurepart;
}

export class ResourceRow extends React.Component<IResourceUserRowProps, IResourceUserRowStates>{
    constructor(props: IResourceUserRowProps) {
        super(props);

        this.state = {
            userHighlight: {
                state: false,
                userID: ""
            },
            showContextMenu: false
        };
    }

    public render(): React.ReactElement<{}> {
        let isOnMobile: boolean = this.props.clientType == "android" || this.props.clientType == "ios";
        let darkClassName = this.props.theme == "dark" ? " dark" : "";

        return (
            <React.Fragment>
                <tr className={this.state.userHighlight.state ? "highlightOn" : "highlightOff"} data-string={this.props.resource.id + "_" + this.props.resource.name}>
                    <th colSpan={2}>
                        <div className={this.props.slimView ? "fixedTableAreaSlim" : "fixedTableArea"} >
                            {!isOnMobile && <div className={(this.props.slimView ? "staticNameCellGroupSlim" : "staticNameCellGroup") + darkClassName}>{this.props.firstRow && this.props.resource.category}</div>}
                            <div className={(this.props.slimView ? "staticNameCellUserSlim" : "staticNameCellUser") + darkClassName} onClick={() => this._setHighlight(this.props.resource.id)} style={{ cursor: "pointer" }}>

                                <div className={"workerNameCell"}>
                                    {this._isCurrentUser(this.props.resource.id) ? <span className={this.props.slimView ? "workerNameCellStringSlim" : "workerNameCellString"}><strong>{isOnMobile ? this.props.resource.initials : this.props.resource.name}</strong></span> : <span className={this.props.slimView ? "workerNameCellStringSlim" : "workerNameCellString"}>{isOnMobile ? this.props.resource.initials : this.props.resource.name}</span>}
                                    {/* {<IconButton className={"iconButtonContextMenu" + (this.props.slimView ? " slimContextButton" : "")} menuProps={{
                                        items: [
                                            {
                                                key: 'editWorker',
                                                text: 'Edit',
                                                iconProps: { iconName: 'Edit' },
                                                onClick: () => this._editWorker()
                                            },
                                            {
                                                key: 'deleteWorker',
                                                text: 'Delete',
                                                iconProps: { iconName: 'Delete' },
                                                onClick: () => this.props.removeStructurepart(this.props.resource)
                                            }
                                        ]
                                    }} iconProps={{ iconName: 'MoreVertical' }} title="Menu" ariaLabel="Menu" />} */}
                                    <Menu
                                        items={[
                                            {
                                                key: 'moreButton',
                                                className: (this.props.slimView ? "slimContextButton" : ""),
                                                indicator: false,
                                                icon: <MoreIcon outline />,
                                                menu: {
                                                    items: [
                                                        {
                                                            key: 'editWorker',
                                                            content: 'Edit',
                                                            icon: <EditIcon outline />,
                                                            onClick: this._editWorker
                                                        },
                                                        {
                                                            key: 'deleteWorker',
                                                            content: 'Delete',
                                                            icon: <TrashCanIcon outline />,
                                                            onClick: () => this.props.removeStructurepart(this.props.resource)
                                                        }
                                                    ]
                                                }
                                            }
                                        ]}
                                        accessibility={menuAsToolbarBehavior}
                                        iconOnly
                                    />

                                </div>
                            </div>
                        </div>
                    </th>
                    {this._generateResourceSelections()}
                </tr>
            </React.Fragment>
        );
    }

    private _editWorker = (): void => {
        this.props.editResource(this.props.resource);
    }

    private _setHighlight = (userID: string): void => {
        this.setState({
            userHighlight: {
                state: !this.state.userHighlight.state,
                userID: userID
            }
        });
    }

    private _generateResourceSelections = (): JSX.Element[] => {
        let startDate = new Date(this.props.startDate);
        //startDate.setDate(new Date().getDate());
        let slotCount = 2;
        let timeSlots = this._createTimeSlots(startDate, slotCount);
        let blockDict = this._createBlockDictionary(this.props.planContent.blocks, this.props.planContent.structure.blocker);
        let filledTimeSlots = this._mapSlotsAndBlocks(timeSlots, blockDict);
        let elements: JSX.Element[] = [];
        let cachedTimeSlots = [];
        let darkClassName = this.props.theme == "dark" ? " darkCell":"";

        //TimeSlots auf Tage aufteilen und für jeden Tag eine ResourceSelection
        filledTimeSlots.forEach((val, index) => {
            cachedTimeSlots.push(val);

            if (cachedTimeSlots.length == slotCount) {
                elements.push(<ResourceSlot
                    timeSlots={cachedTimeSlots}
                    
                    selectedBlocker={this.props.selectedBlocker}
                    selectedSubBlocker={this.props.selectedSubBlocker}
                    slimView={this.props.slimView}
                    refreshBlockData={this.props.refreshBlockData}
                    alldayBlock={this.props.alldayBlock}
                    editMode={this.props.editMode}
                    userId={this.props.resource.id}
                    darkClassName={darkClassName}
                />);

                cachedTimeSlots = [];
            }
        });
        
        return elements;
    }

    private _createTimeSlots = (startDate: Date, timeSlotCount = 2, weekCount = 4): ITimeSlot[] => {
        let daysInFirstWeek = startDate.getDay() === 0 ? 1 : 8 - startDate.getDay();
        let dayCount = (weekCount * 7) + daysInFirstWeek;
        let allTimeSlots: ITimeSlot[] = [];

        for (let i = 0; i < dayCount; i++) {
            let currentDate = new Date(startDate);

            for (let j = 0; j < timeSlotCount; j++) {
                allTimeSlots.push({
                    Date: currentDate,
                    Slot: j + 1,
                    Key: startDate.getEightDigitString("") + "_" + (j + 1)
                });
            }

            startDate.setDate(startDate.getDate() + 1);
        }

        return allTimeSlots;
    }

    private _mapSlotsAndBlocks = (timeSlots: ITimeSlot[], blocks: { [id: string]: IMatchedBlock }): ITimeSlot[] => {
        timeSlots.forEach((currentSlot: ITimeSlot) => {
            if (blocks.hasOwnProperty(currentSlot.Key)) {
                currentSlot.Block = blocks[currentSlot.Key].Block;
                currentSlot.Blocker = blocks[currentSlot.Key].Blocker;
            }
            else {
                currentSlot.Block = undefined;
                currentSlot.Blocker = undefined;
            }
        });

        return timeSlots;
    }

    private _createBlockDictionary = (blocks: IBlock[], blockers: IStructurepart[]): { [id: string]: IMatchedBlock } => {
        let dictionary: { [id: string]: IMatchedBlock } = {};
        let cachedBlockers = {};
        let cachedColors = {};
        blocks.forEach((currentBlock: IBlock) => {
            if (currentBlock.resourceId == this.props.resource.id) {
                currentBlock.start = new Date(currentBlock.start);
                let key = currentBlock.start.getEightDigitString("") + "_" + currentBlock.timeSlot;

                if (cachedBlockers.hasOwnProperty(currentBlock.blockerId)) {
                    dictionary[key] = { Block: currentBlock, Blocker: cachedBlockers[currentBlock.blockerId] };
                } else {
                    for (let i = 0; i < blockers.length; i++) {
                        if (blockers[i].id == currentBlock.blockerId) {
                            dictionary[key] = { Block: currentBlock, Blocker: blockers[i] };
                            cachedBlockers[blockers[i].id] = blockers[i];
                            break;
                        }
                    }
                }
                if (dictionary[key] != undefined) {
                    if (cachedColors.hasOwnProperty(dictionary[key].Blocker.parent)) {
                        dictionary[key].Blocker.color = cachedColors[dictionary[key].Blocker.parent];
                    } else {
                        for (let j = 0; j < this.props.planContent.structure.blocker.length; j++) {
                            if (this.props.planContent.structure.blocker[j].id == dictionary[key].Blocker.parent) {
                                dictionary[key].Blocker.color = this.props.planContent.structure.blocker[j].color;
                                cachedColors[this.props.planContent.structure.blocker[j].id] = this.props.planContent.structure.blocker[j].color;
                                break;
                            }
                        }
                    }
                }
            }
        })

        return dictionary;
    }


    private _isCurrentUser = (userId: string): boolean => {
        if (+userId == this.props.currentUserId) {
            return true;
        } else {
            return false;
        }

    }
}

declare global {
    interface Date {
        getEightDigitString(symbol: string): string;
    }
}

Date.prototype.getEightDigitString = function (this: Date, symbol: string) {
    let copiedDate: Date = new Date(this.getTime());

    let dateDate: number = copiedDate.getUTCDate();
    let dateMonth: number = copiedDate.getUTCMonth() + 1;
    let dateYear: number = copiedDate.getUTCFullYear();

    return dateYear + symbol + dateMonth + symbol + dateDate;
};