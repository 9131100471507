import { IPlan } from "../interfaces/IPlan";
import { IStructurepart } from "../interfaces/IStructurepart";
import { IBlock } from "../interfaces/IBlock";
import { INewBlockData } from "../interfaces/INewBlockData";
import { INewStructurepartData } from "../interfaces/INewStructurepartData";
import { INewPlanData } from "../interfaces/INewPlanData";
import { IRemovalData } from "../interfaces/IRemovalData";

//"https://devressourcenplanungdt.azurewebsites.net"https://api.manysource.de
export const url = window.URL.toString().indexOf("localhost") === -1 ? "https://api.manysource.de" : "https://localhost:44360";

export class AzureService {
    private static _token: string = "";
    private static _planId: string = "";

    constructor() { }

    public static setToken = (token: string) => {
        AzureService._token = token;
    }

    public static setPlanId = (planId: string) => {
        AzureService._planId = planId;
    }


    //Plan Controller

    public static getCurrentPlan = async (): Promise<IPlan> => {
        return fetch(url + "/plan/getplan", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(AzureService._planId) //'"c188d12f-e276-41cb-8c29-68e8e6b2fe41"'
        }).then((res) => {
            console.log(res);
            return res.json().then((r) => {
                return r;
            });
        });
    }

    public static getAllPlans = async (): Promise<IPlan[]> => {
        return fetch(url + "/plan/getallplans", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            }
        }).then(res => {
            return res.json().then(r => {
                return r;
            }).catch((fail)=>{
                return null;
            });
        });
    }

    public static addPlan = async (body:INewPlanData): Promise<IPlan> => {
        return fetch(url + "/plan/addplan", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(JSON.stringify(body))
        }).then((res) => {
            console.log(res);
            return res.json().then((r) => {
                return r;
            }).catch((fail)=>{
                return null;
            });
        });
    }

    public static editPlan = async (body:IPlan): Promise<IPlan> => {
        return fetch(url + "/plan/editplan", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(JSON.stringify(body))
        }).then((res) => {
            console.log(res);
            return res.json().then((r) => {
                return r;
            });
        });
    }

    public static deletePlan = async (body:IRemovalData): Promise<boolean> => {
        return fetch(url + "/plan/deleteplan", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(JSON.stringify(body))
        }).then(async (res) => {
            console.log(res);
            return res.json().then((r) => {
                return r;
            });
        });
    }


    //Structure Controller

    public static getStructure = async (): Promise<IStructurepart[]> => {
        return fetch(url + "/structure/getstructure", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(AzureService._planId)
        }).then(res => {
            return res.json().then(r => {
                return r;
            });
        });
    }

    public static editStructure = async (body:IStructurepart): Promise<IStructurepart[]> => {
        return fetch(url + "/structure/editstructure", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(JSON.stringify(body))
        }).then(res => {
            return res.json().then(r => {
                return r;
            });
        });
    }

    public static addStructure = async (body:INewStructurepartData): Promise<IStructurepart[]> => {
        return fetch(url + "/structure/addstructure", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(JSON.stringify(body))
        }).then(res => {
            return res.json().then(r => {
                return r;
            }).catch((fail)=>{
                return null;
            });
        });
    }

    public static deleteStructure = async (body:IStructurepart): Promise<IStructurepart[]> => {
        return fetch(url + "/structure/deletestructure", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(JSON.stringify(body))
        }).then(res => {
            return res.json().then(r => {
                return r;
            });
        });
    }


    //Block Controller

    public static getBlocks = async (): Promise<IBlock[]> => {
        return fetch(url + "/block/getblocks", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body:  JSON.stringify(AzureService._planId)
        }).then(res => {
            return res.json().then(r => {
                return r;
            });
        });
    }

    public static addBlock = async (body:INewBlockData): Promise<IBlock[]> => {
        return fetch(url + "/block/addblock", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(JSON.stringify(body))
        }).then(res => {
            return res.json().then(r => {
                return r;
            });
        });
    }

    public static editBlock = async (body:IBlock): Promise<IBlock[]> => {
        return fetch(url + "/block/editblock", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(JSON.stringify(body))
        }).then(res => {
            return res.json().then(r => {
                return r;
            });
        });
    }

    public static deleteBlock = async (body:IBlock[]): Promise<IBlock[]> => {
        return fetch(url + "/block/deleteblock", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + AzureService._token
            },
            body: JSON.stringify(JSON.stringify(body))
        }).then(res => {
            return res.json().then(r => {
                return r;
            });
        });
    }
}