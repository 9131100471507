import * as React from 'react';
import { IWeek } from '../ResourceCalendar';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { IWeekDays } from '../../interfaces/IWeekDays';
import { IStructurepart } from '../../interfaces/IStructurepart';
import { IPlan } from '../../interfaces/IPlan';

export interface ITableHeadProps {
    choosenWeeks: number;
    currentWeekNumber: number;
    firstWeekDays: number;
    arr_ddMM: IWeek[];
    selectedSubBlocker: IStructurepart;
    selectedBlocker: IStructurepart;
    blockableDays: IWeekDays;
    chosenPlan: IPlan;
    theme: string;
}


export class TableHead extends React.Component<ITableHeadProps, {}>{
    constructor(props: ITableHeadProps) {
        super(props);
    }

    public render(): React.ReactElement<{}> {
        let col = [];
        let selectedProject: JSX.Element;
        let selectedManager: JSX.Element;
        let darkHoverClassName = this.props.theme == "dark" ? " darkhover" : "";
        let darkClassName = this.props.theme == "dark" ? " dark" : "";
        for (let i = 0; i < this.props.choosenWeeks; i++) {
            if (!i) {
                col.push(<td className={"staticCell" + " " + "kw" + darkClassName} colSpan={this._getFirstWeekColSpan()}>{this.props.currentWeekNumber + i}</td>);
            } else {
                col.push(<td className={"staticCell" + " " + "kw" + darkClassName} colSpan={this._getNormalWeekColSpan()}>{this.props.currentWeekNumber + i}</td>);
            }
        }

        if (this.props.selectedSubBlocker) {
            selectedProject = <TooltipHost content={this.props.selectedSubBlocker.name && this.props.selectedSubBlocker.name + " (" + this.props.selectedSubBlocker.initials + ")"} calloutProps={{ gapSpace: 0 }}>
                {this.props.selectedSubBlocker.name ? this.props.selectedSubBlocker.name + " (" + this.props.selectedSubBlocker.initials + ")" : "-"}
            </TooltipHost>;
        } else {
            selectedProject = <span>{""}</span>;
        }

        if (this.props.selectedBlocker != null) {
            selectedManager = <TooltipHost content={this.props.selectedBlocker.name && this.props.selectedBlocker.name + " (" + this.props.selectedBlocker.initials + ")"} calloutProps={{ gapSpace: 0 }}>
                {this.props.selectedBlocker.name ? this.props.selectedBlocker.name + " (" + this.props.selectedBlocker.initials + ")" : "-"}
            </TooltipHost>;
        } else {
            selectedManager = <span>{""}</span>;
        }

        return (
            <thead>
                <tr>
                    <th colSpan={2} rowSpan={2}>
                        <div id={"showCurrentManagerID"} className={"showCurrentManager" + darkHoverClassName}>
                            <table className={"cmTable"}>
                                {/* <tr>
                                    <td className={"currentProject"}>
                                        {(this.props.chosenPlan ? this.props.chosenPlan.subBlockerLabel : "") + ": "}
                                    </td>
                                    <td className={"currentProjectTitle"}>
                                        {selectedProject}
                                    </td>
                                </tr> */}
                                <tr>
                                    <td className={"currentManager"}>
                                        {(this.props.chosenPlan ? this.props.chosenPlan.blockerLabel : "") + ": "}
                                    </td>
                                    <td className={"currentManagerTitle"}>
                                        <span style={{ borderBottom: this.props.selectedBlocker != null ? "3px solid #" + this.props.selectedBlocker.color : "4px solid #FFFFFF" }}>{selectedManager}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </th>

                    {col}
                </tr>
                {this._generateTimeline()}
            </thead>
        );
    }


    private _getFirstWeekColSpan = (): number => {
        let weekdays: string[] = ["sunday", "saturday", "friday", "thursday", "wednesday", "tuesday", "monday"];
        let colSpan: number = 0;
        for (let i = 0; i < this.props.firstWeekDays + 1; i++) {
            if (this.props.blockableDays[weekdays[i]]) {
                colSpan += 2;
            } else {
                colSpan++;
            }
        }
        return colSpan;
    }

    private _getNormalWeekColSpan = (): number => {
        let weekdays: string[] = ["sunday", "saturday", "friday", "thursday", "wednesday", "tuesday", "monday"];
        let colSpan: number = 0;
        for (let i = 0; i < weekdays.length; i++) {
            if (this.props.blockableDays[weekdays[i]]) {
                colSpan += 2;
            } else {
                colSpan++;
            }
        }
        return colSpan;
    }

    private _generateTimeline = (): any[] => {
        let timeline = [];
        let sumOfWN: number = this.props.firstWeekDays + ((this.props.choosenWeeks - 1) * 7) + 1;
        let darkClassName = this.props.theme == "dark" ? " dark" : "";
        for (let i = 0; i < sumOfWN; i++) {
            let todayState: boolean = this._today(this.props.arr_ddMM[i].date);
            timeline.push(
                <td className={(todayState ? "cellToday" : "cellTimelineAndWE") + darkClassName} key={i} colSpan={this._checkForBlockingDays(this.props.arr_ddMM[i].weekDay)}>
                    <TooltipHost content={todayState ? "Today" : ""} calloutProps={{ gapSpace: 0 }}>
                        <div>
                            {this.props.arr_ddMM[i].weekDay}
                        </div>
                        <div className={"ddMM"}>
                            {this.props.arr_ddMM[i].ddMM + "."}
                        </div>
                    </TooltipHost>
                </td>
            );
        }
        return timeline;
    }

    private _checkForBlockingDays = (day: string): number => {
        switch (day) {
            case 'Mon': {
                if (this.props.blockableDays.monday) {
                    return 2;
                } else {
                    return 0;
                }
            }
            case 'Tues': {
                if (this.props.blockableDays.tuesday) {
                    return 2;
                } else {
                    return 0;
                }
            }
            case 'Wed': {
                if (this.props.blockableDays.wednesday) {
                    return 2;
                } else {
                    return 0;
                }
            }
            case 'Thurs': {
                if (this.props.blockableDays.thursday) {
                    return 2;
                } else {
                    return 0;
                }
            }
            case 'Fri': {
                if (this.props.blockableDays.friday) {
                    return 2;
                } else {
                    return 0;
                }
            }
            case 'Sat': {
                if (this.props.blockableDays.saturday) {
                    return 2;
                } else {
                    return 0;
                }
            }
            case 'Sun': {
                if (this.props.blockableDays.sunday) {
                    return 2;
                } else {
                    return 0;
                }
            }
            default: {
                throw 'Something went wrong in "_checkForBlockingDays"';
            }
        }
    }

    // checks for today
    private _today = (date: string): boolean => {
        let today: Date = new Date();
        today.setUTCHours(0, 0, 0, 0);
        if (today.toISOString() == date) {
            return true;
        } else {
            return false;
        }

    }
}