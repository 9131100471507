import * as React from 'react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { ColorPicker } from 'office-ui-fabric-react/lib/ColorPicker';
import { IColor } from 'office-ui-fabric-react/lib/utilities/color/colors';
import { IStructurepart } from '../interfaces/IStructurepart';
import { IPlanContent } from '../interfaces/IPlanContent';
import { INewStructurepartData } from '../interfaces/INewStructurepartData';
import { AzureService } from '../services/AzureService';
import { IPlan } from '../interfaces/IPlan';
import { Button } from '@fluentui/react-northstar/dist/es/components/Button/Button';
import { Input } from '@fluentui/react-northstar/dist/es/components/Input/Input';
import { Dialog } from '@fluentui/react-northstar/dist/es/components/Dialog/Dialog';
import { FlexItem } from '@fluentui/react-northstar/dist/es/components/Flex/FlexItem';
import { Flex } from '@fluentui/react-northstar/dist/es/components/Flex/Flex';
import { DropdownProps, Dropdown } from '@fluentui/react-northstar/dist/es/components/Dropdown/Dropdown';
import { Text } from '@fluentui/react-northstar/dist/es/components/Text/Text';

export interface IResourceAddEditFormsProps {
    refreshCalendar(): void;
    switchArea: number;
    closePanel?(): void;
    groups: string;
    editResource: IStructurepart;
    editBlocker: IStructurepart;
    editSubBlocker: IStructurepart;
    messageBar(message: JSX.Element, ms?: number): void;
    clearEditItem(): void;
    planContent: IPlanContent;
    refreshStructure: Function;
    chosenPlan: IPlan;
}

export interface IResourceAddEditFormsStates {
    ppError: JSX.Element;
    requiredFields: JSX.Element;
    essenceId: string;
    active: boolean;
    title: string;
    initials: string;
    color: string;
    group: string;
    ddIndex: number;
    nameStr: string;
    disableButton: boolean;

    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;

    dialogHiddenForProjectDeactivation: boolean;
    groupValue: any;
}

export class ResourceAddEditForms extends React.Component<IResourceAddEditFormsProps, IResourceAddEditFormsStates>{

    constructor(props: IResourceAddEditFormsProps) {
        super(props);

        this.state = {
            disableButton: false,
            ddIndex: null,
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            group: "",
            color: "",
            requiredFields: <span></span>,
            ppError: <span></span>,
            essenceId: "",
            active: null,
            title: "",
            initials: "",
            nameStr: "",
            dialogHiddenForProjectDeactivation: true,
            groupValue: undefined
        };
    }

    public render(): React.ReactElement<{}> {

        return (
            <div className={"ressourcenplanung"}>
                <Dialog
                    open={!this.state.dialogHiddenForProjectDeactivation}
                    header="Deactivating Blocker"
                    cancelButton="Cancel"
                    confirmButton="Deactivate"
                    onCancel={this._closeDialog}
                    onConfirm={() => this._updateSubBlocker2()}
                    content={
                        <div className={"removingDialogText"}>
                            <span>{"You're going to deactivate this subblocker: "} </span> <br />
                            <strong>{this.props.editSubBlocker ? this.props.editSubBlocker.name : ""}</strong> <br /> <br />

                            <span style={{ fontWeight: "bold", marginTop: "20px" }}>{"Are you sure?"}</span>
                        </div>
                    }
                />

                <div className={"addingForms"}>
                    {this.state.requiredFields}
                    <div hidden={this.props.switchArea !== 1 && this.props.editSubBlocker == undefined}>
                        <Flex gap="gap.small" column>
                            <Input
                                label="Subblocker name: "
                                required
                                value={this.state.title ? this.state.title : this.props.editSubBlocker ? this.props.editSubBlocker.name : ""}
                                onChange={(ev, newProps) => this.setState({ ...this.state, title: newProps.value })} />

                            <Input
                                disabled={this.state.disableButton}
                                label="Subblocker initials: "
                                required
                                value={this.state.initials ? this.state.initials : this.props.editSubBlocker ? this.props.editSubBlocker.initials : ""}
                                onChange={(ev, newProps) => this.setState({ ...this.state, initials: newProps.value })} />

                            <Text
                                styles={{
                                    marginBottom: "0.25rem",
                                    lineHeight: "1rem",
                                }}
                                content="Select associated Blocker: " />
                            <Dropdown
                                disabled={this.state.disableButton}
                                items={this._getBlockerOptions(this.props.planContent.structure.blocker)}
                                value={this.state.essenceId ? this.state.essenceId : (this.props.editSubBlocker != undefined ? this.props.editSubBlocker.parent : this.props.planContent.structure.blocker[0] ? this.props.planContent.structure.blocker[0].id : "")}
                                onChange={this._onChangeBlockerDropDown} />
                        </Flex>

                        <div style={{ marginTop: '35px' }}>
                            <Button primary disabled={this.state.disableButton} onClick={() => this.props.editSubBlocker ? this._updateSubBlocker1() : this._addSubBlocker()} style={{ marginRight: '8px' }} content={this.props.editSubBlocker ? "Update" : "Add"} />
                            <Button disabled={this.state.disableButton} onClick={() => this._cancelAdding()} content="Cancel" />
                        </div>

                    </div>
                    <div hidden={this.props.switchArea !== 2 && this.props.editBlocker == undefined}>
                        {this.state.ppError}
                        <Flex gap="gap.small" column>
                            <Input
                                label="Blocker name: "
                                required
                                value={this.state.title ? this.state.title : this.props.editBlocker != undefined ? this.props.editBlocker.name : ""}
                                onChange={(ev, newProps) => this.setState({ ...this.state, title: newProps.value })} />

                            <Input
                                disabled={this.state.disableButton}
                                label="Initials: "
                                required
                                value={this.state.initials ? this.state.initials : this.props.editBlocker != undefined ? this.props.editBlocker.initials : ""}
                                onChange={(ev, newProps) => this.setState({ ...this.state, initials: newProps.value })} />

                            <Text
                                styles={{
                                    marginBottom: "0.25rem",
                                    lineHeight: "1rem",
                                }}
                                content="Assign a color to him:" />

                            <ColorPicker
                                color={this.state.color ? "#" + this.state.color : this.props.editBlocker != undefined ? this.props.editBlocker.color : ""}
                                onChange={this._updateColor}
                                alphaSliderHidden={true} />
                        </Flex>

                        <div style={{ marginTop: '25px' }}>
                            <Flex>
                                <span></span>
                                <FlexItem push>
                                    <Flex gap="gap.small">
                                        <Button disabled={this.state.disableButton} onClick={() => this._cancelAdding()} content="Cancel" />
                                        <Button primary disabled={this.state.disableButton} onClick={() => this.props.editBlocker ? this._updateBlocker() : this._addBlocker()} style={{ marginRight: '8px' }} content={this.props.editBlocker ? "Update" : "Add"} />
                                    </Flex>
                                </FlexItem>
                            </Flex>
                        </div>
                    </div>

                    <div hidden={this.props.switchArea !== 3 && this.props.editResource == undefined}>
                        {this.state.ppError}
                        <Flex gap="gap.small" column>
                            <Input
                                label="Resource name: "
                                value={this.state.title ? this.state.title : this.props.editResource ? this.props.editResource.name : ""}
                                onChange={(ev, newProps) => this.setState({ ...this.state, title: newProps.value })} />

                            <Input
                                disabled={this.state.disableButton}
                                label="Initials: "
                                value={this.state.initials ? this.state.initials : this.props.editResource ? this.props.editResource.initials : ""}
                                onChange={(ev, newProps) => this.setState({ ...this.state, initials: newProps.value })} />

                            <Text
                                styles={{
                                    marginBottom: "0.25rem",
                                    lineHeight: "1rem",
                                }}
                                content="Select associated group: " />
                            <Dropdown
                                disabled={this.state.disableButton}
                                items={this._groupItems()}
                                onChange={this._onChangeGroupDropDown}
                                value={this.state.groupValue != undefined ? this.state.groupValue : undefined}
                            />
                        </Flex>

                        <div style={{ marginTop: '25px' }}>
                            <Flex>
                                <span></span>
                                <FlexItem push >
                                    <Flex gap="gap.small">
                                        <Button disabled={this.state.disableButton} onClick={() => this._cancelAdding()} content="Cancel" />
                                        <Button primary disabled={this.state.disableButton} onClick={() => this.props.editResource ? this._updateUser() : this._addUser()} content={this.props.editResource ? "Update" : "Add"} />
                                    </Flex>
                                </FlexItem>
                            </Flex>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private _updateColor = (ev: React.SyntheticEvent<HTMLElement>, colorObj: IColor) => {
        this.setState({ color: "#" + colorObj.hex });
    }

    public componentWillMount() {
        let essenceId: string = "";
        let monday: boolean = null;
        let tuesday: boolean = null;
        let wednesday: boolean = null;
        let thursday: boolean = null;
        let friday: boolean = null;
        if (this.props.switchArea == 1 && this.props.editSubBlocker != undefined) {
            essenceId = this.props.editSubBlocker.parent;
        }
        let groupArray: string[] = this.props.groups.split(",");
        this.setState({
            group: groupArray[0].trim(),
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            essenceId: essenceId,
        });
    }

    private _onChangeGroupDropDown = (event, props: DropdownProps): void => {
        let newVal: any = props.value;
        this.setState({ ...this.state, group: newVal.header, ddIndex: newVal.key, groupValue: newVal });
    }

    private _getIndexOfGroup = (group: string): number => {
        let groupArray: string[] = this.props.groups.split(",");
        for (let i = 0; i < groupArray.length; i++) {
            if (group == groupArray[i].trim()) {
                return i;
            }
        }
        return 0;
    }

    private async _updateUser(): Promise<void> {
        this.setState({ disableButton: true });
        if (this.props.editResource != undefined) {
            if (!this.state.initials && !this.state.group && !this.state.title) {
                this.setState({
                    ...this.state,
                    requiredFields: <MessageBar
                        messageBarType={MessageBarType.info}
                        onDismiss={this._closeMessage}
                        dismissButtonAriaLabel="Close">Nothing was updated.{' '}
                    </MessageBar>
                });
            }
            else {
                let updateResource: IStructurepart = {
                    category: this.state.group ? this.state.group : this.props.editResource.category,
                    color: null,
                    id: this.props.editResource.id,
                    initials: this.state.initials ? this.state.initials : this.props.editResource.initials,
                    name: this.state.title ? this.state.title : this.props.editResource.name,
                    parent: null,
                    planId: this.props.editResource.planId,
                    tenantId: this.props.editResource.tenantId,
                    type: 1
                }
                AzureService.editStructure(updateResource).then((val) => {
                    if (val) {
                        this.setState({
                            ...this.state,
                            group: "",
                            ddIndex: 0,
                            monday: false,
                            tuesday: false,
                            wednesday: false,
                            thursday: false,
                            friday: false,
                            essenceId: null
                        });
                        this.props.refreshStructure();
                        this.props.messageBar(<MessageBar messageBarType={MessageBarType.success} onDismiss={this._closeMessageOfMessageBar} dismissButtonAriaLabel="Close">{this.props.editResource.name} was successfully updated!{' '}</MessageBar>, 5000);
                        this.props.closePanel();
                    }
                    else {
                        this.setState({
                            ...this.state,
                            requiredFields: <MessageBar messageBarType={MessageBarType.error} onDismiss={this._closeMessage} dismissButtonAriaLabel="Close">Something went wrong while updating!{' '}</MessageBar>
                        });
                    }
                });
            }
        }
    }

    private async _updateBlocker(): Promise<void> {
        this.setState({ disableButton: true });


        if (this.props.editBlocker) {
            if (!this.state.initials && !this.state.color && !this.state.title) {
                this.setState({
                    ...this.state,
                    requiredFields: <MessageBar
                        messageBarType={MessageBarType.info}
                        onDismiss={this._closeMessage}
                        dismissButtonAriaLabel="Close">Nothing was updated.{' '}
                    </MessageBar>
                });
            } else {
                let updateBlocker: IStructurepart = {
                    category: null,
                    color: this.state.color ? this.state.color : this.props.editBlocker.color,
                    id: this.props.editBlocker.id,
                    initials: this.state.initials ? this.state.initials : this.props.editBlocker.initials,
                    name: this.state.title ? this.state.title : this.props.editBlocker.name,
                    parent: null,
                    planId: this.props.editBlocker.planId,
                    tenantId: this.props.editBlocker.tenantId,
                    type: 2
                }
                AzureService.editStructure(updateBlocker).then((val) => {
                    if (val) {
                        this.setState({
                            ...this.state,
                            initials: "",
                            color: ""
                        });
                        this.props.refreshStructure();
                        this.props.messageBar(<MessageBar messageBarType={MessageBarType.success} onDismiss={this._closeMessageOfMessageBar} dismissButtonAriaLabel="Close">{this.props.editBlocker.name} was successfully updated!{' '}</MessageBar>, 5000);
                        this.props.closePanel();
                    } else {
                        this.setState({
                            ...this.state,
                            requiredFields: <MessageBar messageBarType={MessageBarType.error} onDismiss={this._closeMessage} dismissButtonAriaLabel="Close">Something went wrong while updating!{' '}</MessageBar>
                        });
                    }
                });
            }
        }
    }

    private _updateSubBlocker1 = (): void => {
        this.setState({ disableButton: true });
        if (this.props.editSubBlocker != undefined) {
            if (!this.state.initials && !this.state.essenceId && !this.state.title) {
                this.setState({
                    ...this.state,
                    requiredFields: <MessageBar
                        messageBarType={MessageBarType.info}
                        onDismiss={this._closeMessage}
                        dismissButtonAriaLabel="Close">Nothing was updated.{' '}
                    </MessageBar>
                });
            } else {
                this._updateSubBlocker2();
            }
        }
    }

    private async _updateSubBlocker2(): Promise<void> {
        let updateBlocker: IStructurepart = {
            category: null,
            color: null,
            id: this.props.editSubBlocker.id,
            initials: this.state.initials ? this.state.initials : this.props.editSubBlocker.initials,
            name: this.state.title ? this.state.title : this.props.editSubBlocker.name,
            parent: this.state.essenceId ? this.state.essenceId : this.props.editSubBlocker.parent,
            planId: this.props.editSubBlocker.planId,
            tenantId: this.props.editSubBlocker.tenantId,
            type: 3
        }

        AzureService.editStructure(updateBlocker).then((val) => {
            if (val) {
                this.setState({
                    ...this.state,
                    initials: "",
                    title: "",
                    active: true,
                    essenceId: ""
                });
                this.props.refreshStructure();
                this.props.messageBar(<MessageBar messageBarType={MessageBarType.success} onDismiss={this._closeMessageOfMessageBar} dismissButtonAriaLabel="Close">{this.props.editSubBlocker.name} was successfully updated!{' '}</MessageBar>, 5000);
                this.props.closePanel();
            } else {
                this.setState({
                    ...this.state,
                    requiredFields: <MessageBar messageBarType={MessageBarType.error} onDismiss={this._closeMessage} dismissButtonAriaLabel="Close">Something went wrong while updating!{' '}</MessageBar>
                });
            }
        });


    }

    private _groupItems = (): any[] => {
        let items: any[] = [];
        let groupArray: string[] = this.props.groups.split(",");

        groupArray.forEach((item, index) => {
            items.push({
                key: index,
                header: item.trim(),
            });
        });
        return items;
    }

    private _onChangeBlockerDropDown = (event, props: DropdownProps): void => {
        let value: any = props.value;
        this.setState({ ...this.state, essenceId: value.key.toString() });
    }

    private _getBlockerOptions = (blockers: IStructurepart[]): any[] => {
        let options: any[] = [];

        blockers.forEach((item, index) => {
            options.push({
                key: item.id,
                header: item.name
            });
        });
        return options;
    }

    // ADD USER/WORKER TO TABLE CONTEXT
    private async _addUser(): Promise<void> {
        this.setState({ disableButton: true });
        if (this.state.group && this.state.title) {
            let newResource: INewStructurepartData = {
                Category: this.state.group,
                Color: null,
                Initials: this.state.initials,
                Name: this.state.title,
                Parent: null,
                PlanId: this.props.chosenPlan.id,
                TenantId: this.props.chosenPlan.tenantId,
                Type: 1
            }

            AzureService.addStructure(newResource).then((val) => {
                if (val != null) {
                    this.setState({
                        ...this.state,
                        group: "",
                        ddIndex: 0,
                        essenceId: null,
                        title: ""
                    });
                    this.props.refreshStructure();
                    this.props.messageBar(<MessageBar messageBarType={MessageBarType.success} onDismiss={this._closeMessageOfMessageBar} dismissButtonAriaLabel="Close">Resource was successfully added!</MessageBar>, 5000);
                    this.props.closePanel();
                } else {
                    this.setState({
                        ...this.state,
                        requiredFields: <MessageBar messageBarType={MessageBarType.warning} onDismiss={this._closeMessage} dismissButtonAriaLabel="Close">You have reached the limit of available resources!</MessageBar>
                    });
                }
            });
        } else {
            this.setState({ requiredFields: <MessageBar messageBarType={MessageBarType.error} onDismiss={this._closeMessage} dismissButtonAriaLabel="Close"> Some fields are wrong!{' '}</MessageBar> });
        }
        this.setState({ disableButton: false });
    }

    private async _addBlocker(): Promise<void> {
        this.setState({ disableButton: true });
        if (this.state.initials && this.state.title && this.state.color) {
            let newBlocker: INewStructurepartData = {
                Category: null,
                Color: this.state.color,
                Initials: this.state.initials,
                Name: this.state.title,
                Parent: null,
                PlanId: this.props.chosenPlan.id,
                TenantId: this.props.chosenPlan.tenantId,
                Type: 2
            }
            AzureService.addStructure(newBlocker).then((val) => {
                if (val != null) {
                    this.setState({
                        ...this.state,
                        color: "",
                        initials: "",
                        essenceId: null,
                        title: ""
                    });
                    this.props.refreshStructure();
                    this.props.messageBar(<MessageBar messageBarType={MessageBarType.success} onDismiss={this._closeMessageOfMessageBar} dismissButtonAriaLabel="Close"> Blocker was successfully added!</MessageBar>, 5000);
                    this.props.closePanel();
                } else {
                    this.setState({
                        ...this.state,
                        requiredFields: <MessageBar messageBarType={MessageBarType.warning} onDismiss={this._closeMessage} dismissButtonAriaLabel="Close">You have reached the limit of available blockers!</MessageBar>,
                    });
                }
            });
        } else {
            this.setState({ requiredFields: <MessageBar messageBarType={MessageBarType.error} onDismiss={this._closeMessage} dismissButtonAriaLabel="Close"> Some fields are wrong!{' '}</MessageBar> });
        }
        this.setState({ disableButton: false });
    }

    // ADD BLOCKER TO TABLE CONTEXT
    private async _addSubBlocker(): Promise<void> {
        this.setState({ disableButton: true });
        if (this.state.title && this.state.initials && this.state.essenceId) {
            let newBlocker: INewStructurepartData = {
                Category: null,
                Color: null,
                Initials: this.state.initials,
                Name: this.state.title,
                Parent: this.state.essenceId,
                PlanId: this.props.chosenPlan.id,
                TenantId: this.props.chosenPlan.tenantId,
                Type: 3
            }
            AzureService.addStructure(newBlocker).then((val) => {
                if (val) {
                    this.setState({
                        ...this.state,
                        title: "",
                        initials: "",
                        active: true,
                        essenceId: null
                    });
                    this.props.refreshStructure();
                    this.props.messageBar(<MessageBar messageBarType={MessageBarType.success} onDismiss={this._closeMessageOfMessageBar} dismissButtonAriaLabel="Close"> {this.state.title} was successfully created!{' '}</MessageBar>, 5000);
                    this.props.closePanel();
                } else {
                    this.setState({
                        ...this.state,
                        requiredFields: <MessageBar messageBarType={MessageBarType.error} onDismiss={this._closeMessage} dismissButtonAriaLabel="Close">Something went wrong!{' '}</MessageBar>,
                    });
                }
            })
        } else {
            this.setState({ requiredFields: <MessageBar messageBarType={MessageBarType.error} onDismiss={this._closeMessage} dismissButtonAriaLabel="Close"> Some fields are wrong!{' '}</MessageBar> });
        }
        this.setState({ disableButton: false });
    }

    private _closeMessage = (): void => {
        this.setState({ requiredFields: <span></span> });
    }

    private _closeMessageOfMessageBar = (): void => {
        this.props.messageBar(<span></span>);
    }

    private _cancelAdding = (): void => {
        this.props.closePanel();
        this.props.clearEditItem();
        this.setState({
            title: "",
            initials: "",
            active: true,
            color: "",
            essenceId: null,
            group: "",
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false
        });
    }

    private _closeDialog = (): void => {
        this.setState({ dialogHiddenForProjectDeactivation: true, disableButton: false });
    }
}