import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import * as microsoftTeams from '@microsoft/teams-js';
import { Provider } from '@fluentui/react-northstar/dist/es/components/Provider/Provider';
import { teamsTheme } from '@fluentui/react-northstar/dist/es/themes/teams';
import { teamsDarkTheme } from '@fluentui/react-northstar/dist/es/themes/teams-dark';
import { teamsHighContrastTheme } from '@fluentui/react-northstar/dist/es/themes/teams-high-contrast';
import App from './App';
//import * as serviceWorker from './serviceWorker';
// import { initializeFileTypeIcons } from '@uifabric/file-type-icons';

// initializeFileTypeIcons('https://spoprod-a.akamaihd.net/files/fabric/assets/item-types-fluent/');

microsoftTeams.initialize(() => {
    microsoftTeams.getContext((context) => {
        let selectedTheme = teamsTheme;

        if (context.theme == "dark") {
            selectedTheme = teamsDarkTheme;
        } 
        else if(context.theme == "contrast") {
            selectedTheme = teamsHighContrastTheme;
        }

        ReactDOM.render(<Provider theme={selectedTheme}><App /></Provider>, document.getElementById('root'));
    })
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();