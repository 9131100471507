import * as React from 'react';
import { IPlan } from '../interfaces/IPlan';
import { Dropdown, DropdownProps } from '@fluentui/react-northstar/dist/es/components/Dropdown/Dropdown';
import { INewPlanData } from '../interfaces/INewPlanData';
import { AzureService } from '../services/AzureService';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { IStructurepart } from '../interfaces/IStructurepart';
import { Button } from '@fluentui/react-northstar/dist/es/components/Button/Button';
import { AddIcon, CloseIcon, EditIcon, SettingsIcon } from '@fluentui/react-icons-northstar';
import { Flex } from '@fluentui/react-northstar/dist/es/components/Flex/Flex';
import { Input } from '@fluentui/react-northstar/dist/es/components/Input/Input';
import { FlexItem } from '@fluentui/react-northstar/dist/es/components/Flex/FlexItem';
import { Dialog } from '@fluentui/react-northstar/dist/es/components/Dialog/Dialog';
import { Text } from '@fluentui/react-northstar/dist/es/components/Text/Text';
import { IRemovalData } from '../interfaces/IRemovalData';
import { TextArea } from '@fluentui/react-northstar/dist/es/components/TextArea/TextArea';

export interface IResourcePlanPanelProps {
    chosenPlan: IPlan;
    setMessage: Function;
    refreshPlans: Function;
    resources: IStructurepart[];
    planItems: any[];
    onPlanChosen(event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, data: DropdownProps): void;
    loadingStatus: JSX.Element;
    chosenDropdownOption: any;
}

export interface IResourcePlanPanelState {
    panelOpen: boolean;
    panelHeader: string;
    panelType: PanelMode;
    name: string;
    description: string;
    resourceLabel: string;
    blockerLabel: string;
    subblockerLabel: string;
    steps: number;
    timeSlots: number;
    categoryLabel: string;
    planId: string;
    groups: string;
    status: JSX.Element;
    deleteDialogOpen: boolean;
}

enum PanelMode {
    Add = 1,
    Edit
}

export default class ResourcePlanPanel extends React.Component<IResourcePlanPanelProps, IResourcePlanPanelState> {
    private static chosenPlanId: string = "";

    constructor(props: IResourcePlanPanelProps) {
        super(props);

        this.state = {
            panelOpen: false,
            panelHeader: "",
            panelType: undefined,
            name: "",
            description: "",
            resourceLabel: "Resource",
            blockerLabel: "Blocker",
            subblockerLabel: "Subblocker",
            steps: 1,
            timeSlots: 2,
            categoryLabel: "Category",
            planId: null,
            groups: "",
            status: <></>,
            deleteDialogOpen: false
        }
    }

    static getDerivedStateFromProps(nextProps: IResourcePlanPanelProps, prevState: IResourcePlanPanelState) {
        if (nextProps.chosenPlan != undefined && ResourcePlanPanel.chosenPlanId != nextProps.chosenPlan.id && prevState.panelType == PanelMode.Edit) {
            prevState.description = nextProps.chosenPlan.description;
            prevState.name = nextProps.chosenPlan.name;
            prevState.groups = nextProps.chosenPlan.groups;
            ResourcePlanPanel.chosenPlanId = nextProps.chosenPlan.id;
        }
        else if (nextProps.chosenPlan == undefined && ResourcePlanPanel.chosenPlanId != "") {
            prevState.description = "";
            prevState.name = "";
            prevState.groups = "";
            ResourcePlanPanel.chosenPlanId = "";
        }

        return prevState;
    }

    public render() {
        return (
            <div className="resourcePlanPanel">
                <div className="resourcePlanPanelButtons">
                    <Flex gap="gap.small">
                        <Dropdown
                            className={"planDropdown"}
                            items={this.props.planItems}
                            value={this.props.chosenDropdownOption}
                            onChange={this.props.onPlanChosen}>
                        </Dropdown>
                        <Button
                            content="New Plan"
                            icon={<AddIcon />}
                            onClick={() => { this.setState({ panelType: PanelMode.Add, panelHeader: "New Plan", panelOpen: true }) }}
                        />
                        {this.props.loadingStatus}
                        <FlexItem push>
                            <Button
                                text
                                iconOnly
                                icon={<SettingsIcon outline />}
                                disabled={this.props.chosenPlan == undefined}
                                onClick={this.openEditPanel}
                            />
                        </FlexItem>
                    </Flex>
                </div>
                <Dialog
                    cancelButton={"Cancel"}
                    closeOnOutsideClick={false}
                    confirmButton={
                        this.state.panelType == PanelMode.Add ? "Add plan" : "Confirm changes"
                    }
                    onConfirm={
                        this.state.panelType == PanelMode.Add ? this.addPlan : this.editPlan
                    }
                    onCancel={this.closePanel}
                    headerAction={{
                        icon: <CloseIcon />,
                        title: 'Close',
                        onClick: this.closePanel,
                    }}
                    footer={{
                        children: (Component, props) => {
                          const { styles, ...rest } = props
                          return (
                            <Flex styles={styles}>
                              {this.state.panelType == PanelMode.Edit && <Button text primary content="Delete plan" onClick={()=>{this.setState({deleteDialogOpen:true});}}/>}
                              <Flex.Item push>
                                <Component {...rest} />
                              </Flex.Item>
                            </Flex>
                          )
                        }
                    }}
                    open={this.state.panelOpen}
                    header={this.state.panelHeader}
                    content={
                        <Flex gap="gap.small" column>
                            {this.state.status}

                            <Input
                                label="Name"
                                value={this.state.name}
                                variables={{
                                    width: "300px"
                                }}
                                onChange={(event, newVal) => { this.setState({ name: newVal.value }); }}
                            />
                            <Text
                                styles={{
                                    marginBottom: "0.25rem",
                                    lineHeight: "1rem",
                                }}
                                content="Description" 
                            />
                            <TextArea
                                value={this.state.description}
                                variables={{
                                    height:"80px",
                                    width:"300px"
                                }}
                                fluid={false}
                                onChange={(event, newVal) => { this.setState({ description: newVal.value }); }}
                            />
                            <Input
                                label="Resource Groups (group names separated by ',')"
                                value={this.state.groups}
                                variables={{
                                    width: "300px"
                                }}
                                onChange={(event, newVal) => { this.setState({ groups: newVal.value }); }}
                            />
                            {/* <Input
                                label="Resource Label"
                                value={this.state.resourceLabel}
                                onChange={(event, newVal) => { this.setState({ resourceLabel: newVal.value }); }}
                            />

                            <Input
                                label="Category Label"
                                value={this.state.categoryLabel}
                                onChange={(event, newVal) => { this.setState({ categoryLabel: newVal.value }); }}
                            />

                            <Input
                                label="Blocker Label"
                                value={this.state.blockerLabel}
                                onChange={(event, newVal) => { this.setState({ blockerLabel: newVal.value }); }}
                            />

                            <Input
                                label="Subblocker Label"
                                value={this.state.subblockerLabel}
                                onChange={(event, newVal) => { this.setState({ subblockerLabel: newVal.value }); }}
                            /> 
                            
                            Step Auswahl nur wenn Plan neu erstellt wird => nicht editierbar
                            {(this.state.panelType == PanelMode.Add) && (<>
                                <Text
                                    styles={{
                                        marginBottom: "0.25rem",
                                        lineHeight: "1rem",
                                    }}
                                    content="Step count"
                                />
                                <Dropdown
                                    items={[
                                        {
                                            key: 1,
                                            header: "1 Step"
                                        },
                                        {
                                            key: 2,
                                            header: "2 Steps"
                                        }
                                    ]}

                                    onChange={(event, newVal: any) => { this.setState({ steps: newVal.value.key }); }}
                                />
                            </>)}

                            {(this.state.panelType == PanelMode.Add) && <Input
                                label="Time slots"
                                type="number"
                                value={this.state.timeSlots}
                                onChange={(event, newVal) => { if (parseInt(newVal.value) > 0) { this.setState({ timeSlots: parseInt(newVal.value) }); } }}
                            />} */}
                            <Dialog
                            open={this.state.deleteDialogOpen}
                            header="Delete Plan"
                            cancelButton="Cancel"
                            onCancel={()=>{this.setState({deleteDialogOpen:false});}}
                            confirmButton="Delete"
                            onConfirm={()=>{this.deletePlan()}}
                            content="Are you sure you want to delete this plan?"
                            />
                        </Flex>
                    }
                >
                </Dialog>
            </div>
        );
    }

    private openEditPanel = () => {
        if (this.props.chosenPlan != undefined) {
            this.setState({
                panelType: PanelMode.Edit,
                panelHeader: "Edit current plan",
                panelOpen: true
            });
        } else {
            this.props.setMessage(<MessageBar messageBarType={MessageBarType.warning} onDismiss={() => this.props.setMessage(<span></span>)} dismissButtonAriaLabel="Close">Please choose a plan to edit!</MessageBar>);
        }
    }

    private renderPanelFooter = () => {
        let primaryButton = this.state.panelType == PanelMode.Add ?
            <Button content="Add plan" primary onClick={this.addPlan} /> :
            <Button content="Confirm changes" primary onClick={this.editPlan} />;

        return (<Flex gap="gap.small">
            {primaryButton}
            <Button
                content="Cancel"
                secondary
                onClick={this.closePanel}
            />
        </Flex>);
    }

    private clearValues = () => {
        this.setState({
            name: "",
            description: "",
            panelHeader: "",
            groups: "",
            panelType: PanelMode.Add,
            deleteDialogOpen: false
        });
        ResourcePlanPanel.chosenPlanId = "";
    }

    private closePanel = () => {
        this.setState({ panelOpen: false }, this.clearValues);
    }

    private addPlan = (): Promise<IPlan> => {
        let validValues = this.validateValues();

        if (validValues) {
            let planData: INewPlanData = {
                BlockerLabel: this.state.blockerLabel,
                CategoryLabel: this.state.categoryLabel,
                Description: this.state.description,
                Name: this.state.name,
                ResourceLabel: this.state.resourceLabel,
                Steps: this.state.steps,
                SubBlockerLabel: this.state.subblockerLabel,
                TimeSlotCount: this.state.timeSlots,
                Groups: this.state.groups.replace(", ", ",")
            }

            return AzureService.addPlan(planData).then((value) => {
                if(value != null){
                    this.closePanel();
                    this.props.refreshPlans(true);
                    return value;
                }
                else {
                    this.setState({ status: 
                        <MessageBar messageBarType={MessageBarType.warning} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">
                        You have reached the limit of available plans!
                        </MessageBar>
                    });
                }
            });
        }
    }

    private editPlan = (): Promise<IPlan> => {
        let validValues = this.validateValues();

        if (validValues) {
            let planData: IPlan = {
                blockerLabel: this.state.blockerLabel,
                categoryLabel: this.state.categoryLabel,
                description: this.state.description,
                name: this.state.name,
                resourceLabel: this.state.resourceLabel,
                steps: this.props.chosenPlan.steps,
                subBlockerLabel: this.state.subblockerLabel,
                timeSlotCount: this.props.chosenPlan.timeSlotCount,
                createdBy: this.props.chosenPlan.createdBy,
                createdDate: this.props.chosenPlan.createdDate,
                id: this.props.chosenPlan.id,
                modifiedBy: this.props.chosenPlan.modifiedBy,
                modifiedDate: this.props.chosenPlan.modifiedDate,
                tenantId: this.props.chosenPlan.tenantId,
                groups: this.state.groups.replace(", ", ",")
            }

            return AzureService.editPlan(planData).then((value) => {
                this.closePanel();
                this.props.refreshPlans(true);
                return value;
            });
        }
    }

    private deletePlan = () => {
        let removalData: IRemovalData = {
            tenantId: this.props.chosenPlan.tenantId,
            id: this.props.chosenPlan.id
        };

        AzureService.deletePlan(removalData).then(()=>{
            this.closePanel();
            this.props.refreshPlans(false);
        });
    }

    private validateValues = (): boolean => {
        let regEx = new RegExp(`^[a-zA-Z0-9äÄöÖüÜ ]*$`);
        let validName = this.state.name.trim().length > 0 && regEx.test(this.state.name);
        let validDesc = this.state.description.trim().length > 0 && regEx.test(this.state.description);
        let validResLabel = this.state.resourceLabel.trim().length > 0 && regEx.test(this.state.resourceLabel);
        let validCatLabel = this.state.categoryLabel.trim().length > 0 && regEx.test(this.state.categoryLabel);
        let validBlockerLabel = this.state.blockerLabel.trim().length > 0 && regEx.test(this.state.blockerLabel);
        let validSubBlockLabel = this.state.subblockerLabel.trim().length > 0 && regEx.test(this.state.subblockerLabel);
        let validGroups = this.validateGroupValue();

        if (this.state.panelType == PanelMode.Edit) {
            if (!validName) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid name!</MessageBar> });
                return false;
            } else if (!validDesc) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid description!</MessageBar> });
                return false;
            } else if (!validResLabel) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid resource label!</MessageBar> });
                return false;
            } else if (!validCatLabel) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid category label!</MessageBar> });
                return false;
            } else if (!validBlockerLabel) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid blocker label!</MessageBar> });
                return false;
            } else if (!validSubBlockLabel) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid subblocker label!</MessageBar> });
                return false;
            } else if (!validGroups) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">There are resources still attached to the removed/changed groups!</MessageBar> });
                return false;
            } else {
                return true;
            }
        }
        else {
            let validSteps = this.state.steps == 1 || this.state.steps == 2;
            let validSlots = this.state.timeSlots > 0 && this.state.timeSlots < 10;

            if (!validName) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid name!</MessageBar> });
                return false;
            } else if (!validDesc) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid description!</MessageBar> });
                return false;
            } else if (!validResLabel) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid resource label!</MessageBar> });
                return false;
            } else if (!validCatLabel) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid category label!</MessageBar> });
                return false;
            } else if (!validBlockerLabel) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid blocker label!</MessageBar> });
                return false;
            } else if (!validSubBlockLabel) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid subblocker label!</MessageBar> });
                return false;
            } else if (!validSteps) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please choose a valid step count!</MessageBar> });
                return false;
            } else if (!validSlots) {
                this.setState({ status: <MessageBar messageBarType={MessageBarType.error} onDismiss={() => this.setState({ status: <span></span> })} dismissButtonAriaLabel="Close">Please enter a valid time slot count!</MessageBar> });
                return false;
            } else {
                return true;
            }
        }
    }

    private validateGroupValue = () => {
        let newValue = this.state.groups.split(",");
        newValue = newValue.map((value) => { return value.trim(); });
        let oldGroups = this.getGroupsFromResources();

        let result = oldGroups.every(val => newValue.includes(val));

        return result;
    }

    private getGroupsFromResources = () => {
        let groups = [];

        this.props.resources.forEach((currentResource) => {
            if (!groups.includes(currentResource.category)) {
                groups.push(currentResource.category);
            }
        });

        return groups;
    }
}
