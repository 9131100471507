import * as React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { ResourceAddEditForms } from './ResourceAddEditForms';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { IAbsence } from '../interfaces/IAbsence';
import { initializeIcons } from '@uifabric/icons';
import { IPlanContent } from '../interfaces/IPlanContent';
import { IStructurepart } from '../interfaces/IStructurepart';
import { IPlan } from '../interfaces/IPlan';
import { AddIcon } from '@fluentui/react-icons-northstar/dist/es/components/AddIcon';
import { Dropdown, DropdownProps } from '@fluentui/react-northstar/dist/es/components/Dropdown/Dropdown';
import { Checkbox } from '@fluentui/react-northstar/dist/es/components/Checkbox/Checkbox';
import { Flex } from '@fluentui/react-northstar/dist/es/components/Flex/Flex';
import { FlexItem } from '@fluentui/react-northstar/dist/es/components/Flex/FlexItem';
import { SplitButton } from '@fluentui/react-northstar/dist/es/components/SplitButton/SplitButton';
import { Dialog } from '@fluentui/react-northstar/dist/es/components/Dialog/Dialog';
import { CloseIcon } from '@fluentui/react-icons-northstar/dist/es/components/CloseIcon';
import { Button } from '@fluentui/react-northstar/dist/es/components/Button/Button';
import { EditIcon } from '@fluentui/react-icons-northstar/dist/es/components/EditIcon';
initializeIcons();

export interface IResourceActionBarProps {
    refreshCalendar(): void;
    waiting: boolean;
    onSubBlockerSelected(subBlocker: IStructurepart): void;
    onEditMode(checked: boolean): void;
    editResource: IStructurepart;
    removeBlocker: Function;
    messageBar(message: JSX.Element, ms?: number): void;
    clearEditItem(): void;
    alldayBlock(value: boolean): void;
    setSelectedBlocker(selectedBlocker: IStructurepart, option: any): void;
    setHighlight(checked: boolean): void;
    setIsOneStep(value: boolean): void;
    planContent: IPlanContent;
    refreshStructure: Function;
    chosenPlan: IPlan;
    setSlimView: Function;
    slimView: boolean;
    selectedBlocker: IStructurepart;
    chosenBlockerOption: any;
    blockerOptions: any;
}

export interface IResourceActionBarState {
    openPanel: boolean;
    panelHeaderText: string;
    disableDropDown: boolean;
    projectCounter: number;
    switchArea: number;
    editResource: IStructurepart;
    editBlocker: IStructurepart;
    editSubBlocker: IStructurepart;
    showInactiveProjects: boolean;
    calloutProjects: string;
    absenceStartDate: Date;
    absenceEndDate: Date;
    disableButton: boolean;
    notification: JSX.Element;
    absences: IAbsence[];
    openSettingsPanel: boolean;
    singleStep: boolean;
    selectedBlocker?: IStructurepart;
    selectedSubBlocker?: IStructurepart;
    showBlockers: boolean;
    searchQuery: string;
}

const DAYPICKERSTRINGS: IDatePickerStrings = {
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker',

    isRequiredErrorMessage: 'Start date is required.',

    invalidInputErrorMessage: 'Invalid date format.'
};

export class ResourceActionBar extends React.Component<IResourceActionBarProps, IResourceActionBarState>{
    constructor(props: IResourceActionBarProps) {
        super(props);

        this.state = {
            openPanel: false,
            switchArea: 0,
            panelHeaderText: "",
            disableDropDown: false,
            editResource: undefined,
            editBlocker: undefined,
            editSubBlocker: undefined,
            projectCounter: 0,
            showInactiveProjects: false,
            calloutProjects: "",
            absenceStartDate: new Date(),
            absenceEndDate: null,
            disableButton: false,
            notification: <span></span>,
            absences: [],
            openSettingsPanel: false,
            singleStep: false,
            showBlockers: true,
            searchQuery: ""
        };
    }

    public componentWillReceiveProps(newProps: IResourceActionBarProps) {
        if (newProps.editResource != undefined) {
            this.setState({ openPanel: true, editResource: newProps.editResource, panelHeaderText: "Edit resource: ", switchArea: 3 });
        }

        if (newProps.chosenBlockerOption != undefined &&
            newProps.chosenBlockerOption.header != (this.props.chosenBlockerOption != undefined ? this.props.chosenBlockerOption.header : "") &&
            newProps.chosenBlockerOption.header != this.state.searchQuery) {
            this.setState({ searchQuery: newProps.chosenBlockerOption.header });
        }
    }

    // static getDerivedStateFromProps(nextProps: IResourceActionBarProps, prevState: IResourceActionBarState) {
    //     if (nextProps.editResource != undefined) {
    //         prevState.openPanel = true;
    //         prevState.editResource = nextProps.editResource;
    //         prevState.panelHeaderText = "Edit resource: ";
    //         prevState.switchArea = 3;
    //     }

    //     if(nextProps.chosenBlockerOption != undefined && nextProps.chosenBlockerOption.header != prevState.searchQuery){
    //         prevState.searchQuery = nextProps.chosenBlockerOption.header;
    //     }

    //     return prevState;
    // }

    public render(): React.ReactElement<{}> {
        let sortedBlockers = this.props.planContent.structure.blocker.sort((a: IStructurepart, b: IStructurepart) => {
            if (a.name === b.name) {
                return 0;
            }
            else {
                return (a.name < b.name) ? -1 : 1;
            }
        });
        console.log("currentOption ", this.props.chosenBlockerOption, " options", this.props.blockerOptions, "query", this.state.searchQuery);
        return (
            <div className={"ressourcenManagement"}>
                <Flex gap="gap.small">
                    <Dropdown
                        search
                        clearable
                        value={this.props.chosenBlockerOption}
                        searchQuery={this.state.searchQuery}
                        placeholder={"Search " + this.props.chosenPlan.blockerLabel + "s..."}
                        items={this.props.blockerOptions}
                        onSearchQueryChange={this._onQueryChange}
                        onChange={this._onBlockerSelect}
                    />
                    <Button
                        icon={<EditIcon />}
                        title="Edit current blocker"
                        text
                        iconOnly
                        disabled={this.props.selectedBlocker == undefined}
                        onClick={() => this.setState({ switchArea: 2, openPanel: true, panelHeaderText: "Edit " + this.props.chosenPlan.blockerLabel + ": ", editBlocker: JSON.parse(JSON.stringify(this.props.selectedBlocker)) })}
                    />
                    <SplitButton
                        menu={[
                            {
                                key: 'addManager',
                                content: 'New ' + (this.props.chosenPlan ? this.props.chosenPlan.blockerLabel : ""),
                                onClick: () => this.setState({ switchArea: 2, openPanel: true, panelHeaderText: "Add a new " + this.props.chosenPlan.blockerLabel + ": " })
                            },
                            // {
                            //     key: 'addProject',
                            //     content: 'New ' + (this.props.chosenPlan ? this.props.chosenPlan.subBlockerLabel : ""),
                            //     onClick: () => this.setState({ switchArea: 1, openPanel: true, panelHeaderText: "Add a new " + this.props.chosenPlan.subBlockerLabel + ": " })
                            // }
                        ]}
                        button={
                            {
                                key: 'addWorker',
                                content: 'New ' + (this.props.chosenPlan ? this.props.chosenPlan.resourceLabel : ""),
                                icon: <AddIcon />
                            }
                        }
                        onMainButtonClick={() => this.setState({ switchArea: 3, openPanel: true, panelHeaderText: "Add a new " + this.props.chosenPlan.resourceLabel + ": " })}
                    />

                    <FlexItem push>
                        <Checkbox label="Edit mode" onChange={(ev, data) => { this.props.onEditMode(data.checked) }} toggle />
                    </FlexItem>
                    {/* <Checkbox label="Highlight mode" onChange={(ev, data) => { this.props.setHighlight(data.checked) }} toggle /> */}

                </Flex>

                {/* <CommandBar
                    items={[
                        {
                            key: 'newItem',
                            name: 'New',
                            iconProps: {
                                iconName: 'Add'
                            },
                            ariaLabel: 'New',
                            subMenuProps: {
                                items: [
                                    {
                                        key: 'addManager',
                                        name: 'New ' + (this.props.chosenPlan ? this.props.chosenPlan.blockerLabel : ""),
                                        iconProps: { iconName: 'AccountManagement' },
                                        onClick: () => this.setState({ switchArea: 2, openPanel: true, panelHeaderText: "Add a new " + this.props.chosenPlan.blockerLabel + ": " })
                                    },
                                    {
                                        key: 'addProject',
                                        name: 'New ' + (this.props.chosenPlan ? this.props.chosenPlan.subBlockerLabel : ""),
                                        iconProps: { iconName: 'NewTeamProject' },
                                        onClick: () => this.setState({ switchArea: 1, openPanel: true, panelHeaderText: "Add a new " + this.props.chosenPlan.subBlockerLabel + ": " })
                                    },
                                    {
                                        key: 'addWorker',
                                        name: 'New ' + (this.props.chosenPlan ? this.props.chosenPlan.resourceLabel : ""),
                                        iconProps: { iconName: 'AddFriend' },
                                        onClick: () => this.setState({ switchArea: 3, openPanel: true, panelHeaderText: "Add a new " + this.props.chosenPlan.resourceLabel + ": " })
                                    }
                                ]
                            }
                        },
                        {
                            key: 'Search',
                            onRender: () => {
                                return <ComboBox options={this._generateDropdownOptions(sortedBlockers)} />
                            }
                        }
                    ]}
                    farItems={[
                        // {
                        //     key: 'showBlockers',
                        //     onRender: () => {
                        //         return (
                        //             <div className={"cbToggles"}>
                        //                 <TooltipHost content={"Show blockers"} calloutProps={{ gapSpace: 0 }}>
                        //                     <Toggle
                        //                         checked={this.state.showBlockers}
                        //                         label="Show blockers"
                        //                         inlineLabel
                        //                         onChange={(ev, checked) => this.setState({ showBlockers: checked })} />
                        //                 </TooltipHost>
                        //             </div>
                        //         );
                        //     }
                        // },
                        {
                            key: 'highlight',
                            onRender: () => {
                                return (
                                    <div className={"cbToggles"}>
                                        {/* <TooltipHost content={"Highlight subblockers"} calloutProps={{ gapSpace: 0 }}>
                                            <Toggle
                                                label="Highlight mode"
                                                inlineLabel
                                                defaultChecked={false}
                                                onChange={(ev, checked) => this.props.setHighlight(checked)} />
                                        </TooltipHost> 
                                        <Checkbox label="Highlight mode" onChange={(ev, data) => { this.props.setHighlight(data.checked) }} toggle />
                                    </div>
                                );
                            }
                        },
                        {
                            key: 'edit',
                            onRender: () => {
                                return (
                                    <div className={"cbToggles"}>
                                        {/* <TooltipHost content={"Use right-click to delete"} calloutProps={{ gapSpace: 0 }}>
                                            <Toggle
                                                defaultChecked={false}
                                                inlineLabel
                                                label="Edit mode"
                                                onChange={(ev, checked) => this.props.onEditMode(checked)} />
                                        </TooltipHost> 
                                        <Checkbox label="Edit mode" onChange={(ev, data) => { this.props.setHighlight(data.checked) }} toggle />
                                    </div>
                                );
                            }
                        },
                        // {
                        //     key: 'wholeDay',
                        //     onRender: () => {
                        //         return (
                        //             <div className={"cbToggles"}>
                        //                 <TooltipHost content={"Block a whole day"} calloutProps={{ gapSpace: 0 }}>
                        //                     <Toggle
                        //                         defaultChecked={false}
                        //                         inlineLabel
                        //                         label="All-day block"
                        //                         onChange={(ev, checked) => this.props.alldayBlock(checked)} />
                        //                 </TooltipHost>
                        //             </div>
                        //         );
                        //     }
                        // }
                    ]} />
                 <div className={"selectionMenu"}>
                    {this.state.showBlockers && <div className={"managerSelection"}>
                        {
                            sortedBlockers.map((blocker: IStructurepart, index: number) => {
                                return (
                                    <ResourceBlocker
                                        selectedBlocker={this.state.selectedBlocker}
                                        onSubBlockerSelect={this._onSubBlockerSelect}
                                        subBlockers={this._getSubBlockers(blocker.id)}
                                        index={index}
                                        blocker={blocker}
                                        editBlocker={this._editBlocker}
                                        editSubBlocker={this._editSubBlocker}
                                        removeBlocker={this.props.removeBlocker}
                                        unselectSubBlockerAndChangeBlocker={this._unselectSubBlockerAndChangeBlocker}
                                        clearCurrentBlocker={this._clearCurrentBlocker} />
                                );
                            })
                        }
                    </div>}
                </div>  */}

                <Dialog
                    open={this.state.openPanel}
                    headerAction={{
                        icon: <CloseIcon />,
                        title: 'Close',
                        onClick: this._closePanel,
                    }}
                    header={this.state.panelHeaderText}
                    content={
                        <>
                            {this.props.waiting && <Spinner size={SpinnerSize.medium} />}

                            <div hidden={this.state.switchArea !== 0}>
                                {this.state.notification}

                                <DatePicker
                                    label="Start date"
                                    isRequired={true}
                                    allowTextInput={true}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={DAYPICKERSTRINGS}
                                    minDate={new Date()}
                                    value={this.state.absenceStartDate && this.state.absenceStartDate}
                                    onSelectDate={this._onSelectStartDate} />

                                <DatePicker
                                    label="End date"
                                    isRequired={true}
                                    allowTextInput={true}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={DAYPICKERSTRINGS}
                                    minDate={new Date()}
                                    value={this.state.absenceEndDate && this.state.absenceEndDate}
                                    onSelectDate={this._onSelectEndDate} />
                            </div>

                            <ResourceAddEditForms
                                clearEditItem={this.props.clearEditItem}
                                editResource={this.state.editResource}
                                editBlocker={this.state.editBlocker}
                                editSubBlocker={this.state.editSubBlocker}
                                refreshCalendar={this.props.refreshCalendar}
                                groups={this.props.chosenPlan ? this.props.chosenPlan.groups : ""}
                                switchArea={this.state.switchArea}
                                messageBar={this.props.messageBar}
                                planContent={this.props.planContent}
                                chosenPlan={this.props.chosenPlan}
                                refreshStructure={this.props.refreshStructure}
                                closePanel={this._closePanel} />
                        </>
                    }
                >


                </Dialog>
            </div>

        );
    }

    private _closePanel = (): void => {
        this.setState({
            openPanel: false,
            editResource: undefined,
            editSubBlocker: undefined,
            editBlocker: undefined,
            panelHeaderText: "",
            absenceEndDate: null,
            absenceStartDate: new Date(),
            notification: <span></span>,
            absences: []
        });
        this.props.clearEditItem();
    }

    private _onQueryChange = (ev, data) => {
        this.setState({ searchQuery: data.searchQuery });
    }

    private _onSelectStartDate = (date: Date | null | undefined): void => {
        this.setState({ absenceStartDate: date });
    }

    private _onSelectEndDate = (date: Date | null | undefined): void => {
        this.setState({ absenceEndDate: date });
    }

    private _onBlockerSelect = (ev, data: any) => {
        if (data.value != null) {
            for (let i = 0; i < this.props.planContent.structure.blocker.length; i++) {
                if (this.props.planContent.structure.blocker[i].id == data.value.key) {
                    this.props.setSelectedBlocker(this.props.planContent.structure.blocker[i], data.value);
                    break;
                }
            }
        }
        else {
            //this.setState({ selectedBlocker: null });
            this._onQueryChange(ev, data);
            this.props.setSelectedBlocker(null, undefined);
        }
    }


    //******************************** */

    // private _onSubBlockerSelect = (subBlockerId: string): void => {
    //     for (let i = 0; i < this.props.planContent.structure.subBlocker.length; i++) {
    //         if (this.props.planContent.structure.subBlocker[i].id == subBlockerId) {
    //             this.setState({
    //                 selectedSubBlocker: {
    //                     id: this.props.planContent.structure.subBlocker[i].id,
    //                     category: this.props.planContent.structure.subBlocker[i].category,
    //                     color: this.props.planContent.structure.subBlocker[i].color,
    //                     initials: this.props.planContent.structure.subBlocker[i].initials,
    //                     name: this.props.planContent.structure.subBlocker[i].name,
    //                     parent: this.props.planContent.structure.subBlocker[i].parent,
    //                     planId: this.props.planContent.structure.subBlocker[i].planId,
    //                     tenantId: this.props.planContent.structure.subBlocker[i].tenantId,
    //                     type: this.props.planContent.structure.subBlocker[i].type
    //                 }
    //             });

    //             this.props.onSubBlockerSelected(this.props.planContent.structure.subBlocker[i]);
    //             break;
    //         }
    //     }
    // }

    // private _getSubBlockers = (blockerId: string): IStructurepart[] => {
    //     let subBlockers: IStructurepart[] = [];
    //     this.props.planContent.structure.subBlocker.forEach((subBlock: IStructurepart) => {
    //         if (subBlock.parent == blockerId) {
    //             subBlockers.push(subBlock);
    //         }
    //     });
    //     return subBlockers;
    // }

    // private _clearCurrentBlocker = (ev: React.MouseEvent<HTMLDivElement>, blocker: IStructurepart): void => {
    //     ev.preventDefault();
    //     if (this.state.selectedBlocker.id == blocker.id) {
    //         let emptyBlocker: IStructurepart = {
    //             category: "",
    //             color: "",
    //             id: "",
    //             initials: "",
    //             name: "",
    //             parent: "",
    //             planId: "",
    //             tenantId: "",
    //             type: -1
    //         }
    //         if (this.state.selectedSubBlocker.id) {
    //             this.setState({ selectedBlocker: emptyBlocker, selectedSubBlocker: emptyBlocker });
    //             this.props.setSelectedBlocker(emptyBlocker);
    //             this.props.onSubBlockerSelected(emptyBlocker);
    //         } else {
    //             this.setState({ selectedBlocker: emptyBlocker });
    //             this.props.setSelectedBlocker(emptyBlocker);
    //         }

    //     }
    // }

    // private _editBlocker = (blocker: IStructurepart): void => {
    //     this.setState({ openPanel: true, panelHeaderText: "Edit blocker: ", switchArea: 2, editBlocker: blocker });
    // }

    // private _editSubBlocker = async (subBlockerId: string): Promise<void> => {
    //     let subBlocker: IStructurepart;
    //     for (let i = 0; i < this.props.planContent.structure.subBlocker.length; i++) {
    //         if (this.props.planContent.structure.subBlocker[i].id == subBlockerId) {
    //             subBlocker = this.props.planContent.structure.subBlocker[i];
    //             break;
    //         }
    //     }
    //     this.setState({ openPanel: true, editSubBlocker: subBlocker, panelHeaderText: "Edit subblocker: ", switchArea: 1 });
    // }




    // private _unselectSubBlockerAndChangeBlocker = (blocker: IStructurepart): void => {
    //     let count: number = 0;
    //     for (let i = 0; i < this.props.planContent.structure.subBlocker.length; i++) {
    //         if (blocker.id == this.props.planContent.structure.subBlocker[i].parent) count++;
    //     }

    //     let emptyBlocker: IStructurepart = {
    //         category: "",
    //         color: "",
    //         id: "",
    //         initials: "",
    //         name: "",
    //         parent: "",
    //         planId: "",
    //         tenantId: "",
    //         type: -1
    //     }

    //     this.props.setSelectedBlocker(blocker);
    //     this.props.onSubBlockerSelected(emptyBlocker);
    //     this.setState({
    //         calloutProjects: blocker.id,
    //         selectedBlocker: blocker,
    //         selectedSubBlocker: emptyBlocker,
    //         projectCounter: count
    //     });
    // }
}