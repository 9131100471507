import { AdalConfig } from 'react-adal';

// App Registration ID
const appId = '46cf6abb-fdcb-4238-bcfe-37a29553e8c2'; //dev 6f38c7e5-41da-4a17-9162-56908a3ef469//release 46cf6abb-fdcb-4238-bcfe-37a29553e8c2
export const adalConfig: AdalConfig = {
    cacheLocation: 'localStorage',
    clientId: appId,
    redirectUri: window.location.origin + "/end.html",
    navigateToLoginRequestUrl:false,
    extraQueryParameter:""
};