import { adalConfig } from '../authentication/adalConfig';
import { AuthenticationContext } from 'react-adal';
import * as microsoftTeams from "@microsoft/teams-js";

export class AuthService {
    private static clientType: microsoftTeams.HostClientType = undefined;

    public setClientType = (value: microsoftTeams.HostClientType) => {
        AuthService.clientType = value;
    }

    public tryLogin = (successCallback, errorCallback, loginHint) => {
        let authContext: AuthenticationContext = new AuthenticationContext(adalConfig);
        if (loginHint) {
            adalConfig.extraQueryParameter = "scope=openid+profile&login_hint=" + encodeURIComponent(loginHint);
        } else {
            adalConfig.extraQueryParameter = "scope=openid+profile";
        }

        // See if there's a cached user and it matches the expected user
        let user = authContext.getCachedUser();
        console.log(user);
        if (user) {
            if (user.userName !== loginHint) {
                // User doesn't match, clear the cache
                authContext.clearCache();
            }
        }

        authContext.acquireToken(adalConfig.clientId, (errorDesc: string, token: string, error: any) => {
            if (token) {
                successCallback(token);
            } else {
                errorCallback(errorDesc);
            }
        });

        // let token = authContext.getCachedToken(adalConfig.clientId);
        // console.log(token);
        // if (token) {
        //     successCallback(token);
        // } else {
        //     if (!authContext.getCachedToken(adalConfig.clientId) || !authContext.getCachedUser()) {
        //         errorCallback("fehler");
        //     } else {
                
        //     }
        // }
    }

    public login = (successCallback, errorCallback) => {
        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/start.html",
            width: 600,
            height: 535,
            successCallback: function (result) {
                // AuthenticationContext is a singleton
                let authContext = new AuthenticationContext(adalConfig);
                let idToken = authContext.getCachedToken(adalConfig.clientId);
                if (idToken) {
                    successCallback(idToken);
                } else {
                    errorCallback("Fehler");
                    console.error("Error getting cached id token. This should never happen.");
                };
            },
            failureCallback: errorCallback
        });
    }

    public start = (loginHint: string) => {
        if (loginHint) {
            adalConfig.extraQueryParameter = "scope=openid+profile&login_hint=" + encodeURIComponent(loginHint);
        } else {
            adalConfig.extraQueryParameter = "scope=openid+profile";
        }

        // Use a custom displayCall function to add extra query parameters to the url before navigating to it
        adalConfig.displayCall = function (urlNavigate) {
            if (urlNavigate) {
                if (urlNavigate.indexOf("scope") === -1) {
                    urlNavigate += "&" + adalConfig.extraQueryParameter;
                }
                window.location.replace(urlNavigate);
            }
        }
        // Navigate to the AzureAD login page        

        let authContext = new AuthenticationContext(adalConfig);
        authContext.login();
    }

    public end = (successCallback: Function, errorCallback: Function) => {
        let authContext = new AuthenticationContext(adalConfig);
        if (authContext.isCallback(window.location.hash)) {
            authContext.handleWindowCallback();
            // Only call notifySuccess or notifyFailure if this page is in the authentication popup

            if (authContext.getCachedUser()) {
                successCallback();
            } else {
                errorCallback(authContext.getLoginError());
            }
        }
    }
}