import * as React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { TableHead, TableBody } from './table/table';
import { IWeekDays } from '../interfaces/IWeekDays';
import { IPlanContent } from '../interfaces/IPlanContent';
import { IStructurepart } from '../interfaces/IStructurepart';
import { HostClientType } from '@microsoft/teams-js';
import { IPlan } from '../interfaces/IPlan';
import { Menu } from '@fluentui/react-northstar/dist/es/components/Menu/Menu';
import { MenuItemProps } from '@fluentui/react-northstar/dist/es/components/Menu/MenuItem';
import { ShorthandCollection } from '@fluentui/react-northstar/dist/es/types';
import { ChevronEndIcon } from '@fluentui/react-icons-northstar/dist/es/components/ChevronEndIcon';
import { ChevronStartIcon } from '@fluentui/react-icons-northstar/dist/es/components/ChevronStartIcon';
import { GalleryNewIcon } from '@fluentui/react-icons-northstar/dist/es/components/GalleryNewIcon';
import { GalleryNewLargeIcon } from '@fluentui/react-icons-northstar/dist/es/components/GalleryNewLargeIcon';
import { Flex } from '@fluentui/react-northstar/dist/es/components/Flex/Flex';
import { FlexItem } from '@fluentui/react-northstar/dist/es/components/Flex/FlexItem';
import { UndoIcon } from '@fluentui/react-icons-northstar/dist/es/components/UndoIcon';
import { Text } from '@fluentui/react-northstar/dist/es/components/Text/Text';
import { MenuButton } from '@fluentui/react-northstar/dist/es/components/MenuButton/MenuButton';

export interface IResourceCalendarProps {
    refreshCalendar(): void;       // All information -> users, managers, projects, assignments
    choosenWeeks: number;       // Number of weeks which will display
    currentUserId: number;      // id of the current user
    editMode: boolean;
    alldayBlock: boolean;
    waitingForCall: boolean;
    startDate: Date;
    messageBar(message: JSX.Element): void;
    removeStructurepart: Function;
    editResource: Function;
    highlight?: boolean;
    blockableDays: IWeekDays;
    planContent: IPlanContent;
    selectedBlocker: IStructurepart;
    selectedSubBlocker: IStructurepart;
    refreshBlockData: Function;
    clientType: HostClientType;
    chosenPlan: IPlan;
    slimView: boolean;
    theme: string;
}

export interface IResourceCalendarStates {
    arr_ddMM: IWeek[];
    firstWeekDays: number;
    currentWeekNumber: number;
    shimmer: boolean;
    messageBar: JSX.Element;
    currentStartDate: Date;
    head: JSX.Element;
    body: JSX.Element;
    nextWeek: boolean;
    inThisPeriod: boolean;
    tableZoom: number;
    disableZoomOut: boolean;
    disableZoomIn: boolean;
    slimView: boolean;
}

export interface IWeek {
    weekDay: string;            // weekday (e.g. Sa, So, Mo...)
    ddMM: string;               // date (e.g. 03.06., 04.06)
    date: string;
}

export class ResourceCalendar extends React.Component<IResourceCalendarProps, IResourceCalendarStates>{
    constructor(props: IResourceCalendarProps) {
        super(props);

        this.state = {
            arr_ddMM: [],
            currentStartDate: new Date(),
            firstWeekDays: 0,
            currentWeekNumber: 0,
            shimmer: true,
            messageBar: <span></span>,
            head: <span></span>,
            body: <span></span>,
            nextWeek: false,
            inThisPeriod: true,
            tableZoom: 1,
            disableZoomOut: false,
            disableZoomIn: false,
            slimView: true
        };
        // this._getTimeInformation();
    }

    public componentWillMount() {
        let date: Date = new Date(Date.UTC(
            this.props.startDate.getFullYear(),
            this.props.startDate.getMonth(),
            this.props.startDate.getDate(),
            12
        ));

        let currentWeekNumber: number = this._getWeekNumber(date);
        let firstWeekDays: number = this._firstWeek(date);
        let arr_ddMM: IWeek[] = this._ddMM(firstWeekDays, date);

        this.setState({ ...this.state, currentWeekNumber, firstWeekDays, arr_ddMM });
    }

    public render(): React.ReactElement<{}> {
        return (
            <div >
                <div className={"window"}>
                    {this.state.messageBar}
                    {/* <div className={"tableControlArea"}>
                        <div className={"homeOnLeft"}>
                            {<IconButton disabled={this.state.inThisPeriod} iconProps={{ iconName: 'GotoToday' }} title="Today" onClick={() => this._startWeek()} />}
                            <div className={"showYear"}>{this.state.currentStartDate.getFullYear()}</div>
                        </div>
                        <div className={"weekSwitchOnRight"}>
                            <div className={"loadingSpinnerManagement"}>
                                {this.props.waitingForCall && <Spinner size={SpinnerSize.medium} />}
                            </div>
                            {<IconButton disabled={this.state.disableZoomOut} iconProps={{ iconName: 'ZoomOut' }} title="ZoomOut" onClick={() => this._tableZoomOut()} />}
                            {<IconButton disabled={this.state.disableZoomIn} iconProps={{ iconName: 'ZoomIn' }} title="ZoomIn" onClick={() => this._tableZoomIn()} />}
                            {<IconButton disabled={this.state.inThisPeriod} iconProps={{ iconName: 'ChevronLeft' }} title="Back" onClick={() => this._previousWeeks()} />}
                            {<IconButton iconProps={{ iconName: 'ChevronRight' }} title="Continue" onClick={() => this._nextWeeks()} />}
                        </div>
                    </div> */}
                    <Flex gap="gap.small">
                        <Menu
                            items={[
                                {
                                    key: "toToday",
                                    icon: <UndoIcon outline />,
                                    onClick: () => { this._startWeek(); },
                                    disabled: this.state.inThisPeriod
                                },
                                {
                                    key: 'divider',
                                    kind: 'divider'
                                },
                                {
                                    key: 'currentYear',
                                    content: this.state.currentStartDate.getFullYear(),
                                    kind: "divider",
                                    styles: { marginLeft: "8px" }
                                }
                            ]}
                            iconOnly
                        />
                        <FlexItem push>
                            <Menu
                                items={this.getMenuItems()}
                                iconOnly
                            />
                        </FlexItem>
                    </Flex>
                    <div className={"fullTable"}>
                        <table style={{ zoom: this.state.tableZoom }} className={"ressourceTable"}>
                            <TableHead
                                blockableDays={this.props.blockableDays}
                                selectedSubBlocker={this.props.selectedSubBlocker}
                                selectedBlocker={this.props.selectedBlocker}
                                choosenWeeks={this.props.choosenWeeks}
                                firstWeekDays={this.state.firstWeekDays}
                                chosenPlan={this.props.chosenPlan}
                                currentWeekNumber={this.state.currentWeekNumber}
                                theme={this.props.theme}
                                arr_ddMM={this.state.arr_ddMM}
                            />
                            <TableBody
                                blockableDays={this.props.blockableDays}
                                selectedBlocker={this.props.selectedBlocker}
                                highlight={this.props.highlight}
                                alldayBlock={this.props.alldayBlock}
                                editResource={this.props.editResource}
                                slimView={this.state.slimView}
                                refreshCalendar={this.props.refreshCalendar}
                                errorMessage={this.props.messageBar}   //Changing messageBar to errorMessage
                                currentUserId={this.props.currentUserId}
                                arr_ddMM={this.state.arr_ddMM}
                                groups={this.props.chosenPlan != undefined ? this.props.chosenPlan.groups : ""}
                                editMode={this.props.editMode}
                                startDate={this.state.currentStartDate}
                                refreshBlockData={this.props.refreshBlockData}
                                firstWeekDays={this.state.firstWeekDays}
                                choosenWeeks={this.props.choosenWeeks}
                                theme={this.props.theme}
                                messageBar={this._getMessageBarInformation}
                                removeStructurepart={this.props.removeStructurepart}
                                clientType={this.props.clientType}
                                selectedSubBlocker={this.props.selectedSubBlocker}
                                planContent={this.props.planContent}
                            />
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    private _tableZoomIn = (): void => {
        if (this.state.tableZoom < 1.2) {
            this.setState({
                tableZoom: this.state.tableZoom + 0.1,
                disableZoomOut: false,
                disableZoomIn: ((this.state.tableZoom + 0.1) >= 1.2) ? true : false
            });
        }
    }

    private _tableZoomOut = (): void => {
        if (this.state.tableZoom > 0.8) {
            this.setState({
                tableZoom: this.state.tableZoom - 0.1,
                disableZoomIn: false,
                disableZoomOut: ((this.state.tableZoom - 0.1) <= 0.8) ? true : false
            });
        }
    }

    private getMenuItems = (): ShorthandCollection<MenuItemProps> => {
        let items: ShorthandCollection<MenuItemProps> = [
            {
                key: 'slimView',
                icon: <GalleryNewLargeIcon outline />,
                onClick: (ev, data) => { this.setState({ slimView: true }); }
            },
            {
                key: 'bigView',
                icon: <GalleryNewIcon outline />,
                onClick: (ev, data) => { this.setState({ slimView: false }); }
            },
            {
                key: 'divider',
                kind: 'divider',

            },
            {
                key: 'backwards',
                icon: <ChevronStartIcon outline />,
                disabled: this.state.inThisPeriod,
                onClick: (ev, data) => { this._previousWeeks(); }
            },
            {
                key: 'forwards',
                icon: <ChevronEndIcon outline />,
                onClick: (ev, data) => { this._nextWeeks(); }
            }

        ];

        return items;
    }

    private _startWeek = (): void => {
        let inThisPeriod: boolean = true;
        let startDate: Date = new Date(this.props.startDate.toISOString());

        let currentWeekNumber: number = this._getWeekNumber(startDate);
        let firstWeekDays: number = this._firstWeek(startDate);
        let arr_ddMM: IWeek[] = this._ddMM(firstWeekDays, startDate);

        this.setState({
            ...this.state,
            arr_ddMM, firstWeekDays,
            currentWeekNumber,
            currentStartDate: new Date(this.props.startDate.toISOString()),
            inThisPeriod
        }, () => this.props.refreshCalendar());
    }

    private _nextWeeks = (): void => {
        let inThisPeriod: boolean = false;
        let days: number = this.state.firstWeekDays + ((this.props.choosenWeeks - 1) * 7) + 1;
        let copyDateObject: Date = new Date(this.state.currentStartDate.toISOString());
        copyDateObject.setDate(copyDateObject.getDate() + days);

        let newDate: Date = new Date(copyDateObject.toISOString());

        let currentWeekNumber: number = this._getWeekNumber(newDate);
        let firstWeekDays: number = this._firstWeek(newDate);
        let arr_ddMM: IWeek[] = this._ddMM(firstWeekDays, newDate);

        this.setState({ ...this.state, arr_ddMM, firstWeekDays, currentWeekNumber, currentStartDate: copyDateObject, inThisPeriod });
    }

    private _previousWeeks = (): void => {
        let inThisPeriod: boolean = false;
        let days: number = this.state.firstWeekDays + ((this.props.choosenWeeks - 1) * 7) + 1;
        let copyDateObject: Date = new Date(this.state.currentStartDate.getTime());
        copyDateObject.setDate(copyDateObject.getDate() - days);

        if (this.props.startDate > copyDateObject) {
            copyDateObject = new Date(this.props.startDate.getTime());
            inThisPeriod = true;
        }

        let newDate: Date = new Date(copyDateObject.getTime());

        let currentWeekNumber: number = this._getWeekNumber(newDate);
        let firstWeekDays: number = this._firstWeek(newDate);
        let arr_ddMM: IWeek[] = this._ddMM(firstWeekDays, newDate);

        this.setState({
            ...this.state,
            arr_ddMM,
            firstWeekDays,
            currentWeekNumber,
            currentStartDate: copyDateObject,
            inThisPeriod
        }, () => inThisPeriod && this.props.refreshCalendar());
    }

    private _getMessageBarInformation = (messageBar: JSX.Element): void => {
        this.setState({ messageBar: messageBar });
    }

    // returns the current number of week in this year
    private _getWeekNumber = (startDate?: Date): number => {
        if (!startDate) {
            startDate = new Date();
            startDate.setUTCHours(12, 0, 0, 0);
        }
        var tdt = new Date(startDate.valueOf());
        var dayn = (startDate.getDay() + 6) % 7;
        tdt.setDate(tdt.getDate() - dayn + 3);
        var firstThursday = tdt.valueOf();
        tdt.setMonth(0, 1);
        if (tdt.getDay() !== 4) {
            tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
        }
        return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
    }

    // returns the number of days for the current week
    private _firstWeek = (startDate?: Date): number => {
        if (!startDate) {
            let startDate = new Date();
            startDate.setUTCHours(12, 0, 0, 0);
        }
        let copiedStartDate: Date = new Date(startDate.getTime());
        let firstWeekDays: number = 7 - copiedStartDate.getDay();
        return firstWeekDays;
    }

    // returns all dates for the timeline (e.g. 05.10, 06.10. ...)
    private _ddMM = (firstWeek: number, startDate?: Date): IWeek[] => {
        let daysOfWeek: string[] = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
        let arr_ddMM: IWeek[] = [];
        let numberOfBodyCol: number = ((this.props.choosenWeeks - 1) * 7);
        for (let i = 0; i < 35; i++) {
            if (!startDate) {
                let startDate: Date = new Date();
            }
            startDate.setUTCHours(12, 0, 0, 0);
            let mm = startDate.getMonth() + 1;
            let dd = startDate.getDate();
            let isoDateString: string = startDate.toISOString();
            arr_ddMM.push({
                weekDay: daysOfWeek[startDate.getDay()],
                ddMM: [(dd > 9 ? '' : '0') + dd, (mm > 9 ? '' : '0') + mm].join('.'),
                date: isoDateString
            });
            startDate.setDate(startDate.getDate() + 1);
        }
        return arr_ddMM;

    }
}