import * as React from 'react';
import './App.css';
import ResourcePlan from './components/ResourcePlan';
import './scss/ResourcePlan.scss';
import 'office-ui-fabric-react/dist/css/fabric.min.css';

const App: React.FC = () => {
  return (
    <ResourcePlan
      blockableDays={{friday:true,monday:true,saturday:true,sunday:true,thursday:true,tuesday:true,wednesday:true}}
      chooseWeeks={5}
    />
  );
}

export default App;