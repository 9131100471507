import * as React from 'react';
import { IWeek } from '../ResourceCalendar';
import { ResourceRow } from '../ResourceRow';
import { IWeekDays } from '../../interfaces/IWeekDays';
import { IStructurepart } from '../../interfaces/IStructurepart';
import { IPlanContent } from '../../interfaces/IPlanContent';
import { HostClientType } from '@microsoft/teams-js';
import { Dropdown } from '@fluentui/react-northstar/dist/es/components/Dropdown/Dropdown';

export interface ITableBodyProps {
    refreshCalendar(): void;
    currentUserId: number;
    arr_ddMM: IWeek[];
    groups: string;
    alldayBlock: boolean;
    firstWeekDays: number;
    choosenWeeks: number;
    selectedSubBlocker: IStructurepart;
    selectedBlocker: IStructurepart;
    editMode: boolean;
    removeStructurepart:Function;
    messageBar(messageBar: JSX.Element): void;
    errorMessage(message: JSX.Element): void;
    editResource: Function;
    highlight: boolean;
    blockableDays: IWeekDays;
    planContent: IPlanContent;
    refreshBlockData: Function;
    startDate:Date;
    clientType:HostClientType;
    slimView: boolean;
    theme: string;
}

export interface ITableBodyStates {
}

export class TableBody extends React.Component<ITableBodyProps, ITableBodyStates>{
    constructor(props: ITableBodyProps) {
        super(props);

        this.state = {
        };
    }

    public render(): React.ReactElement<{}> {

        let preDefinedGroups: string[] = this.getGroups();
        let body_arr: JSX.Element[] = [];
        let firstRow: boolean = true;
        let sortedResourceArray: IStructurepart[] = this.props.planContent.structure.resource.sort((a: IStructurepart, b: IStructurepart) => {
            if (a.name === b.name) {
                return 0;
            }
            else {
                return (a.name < b.name) ? -1 : 1;
            }
        });
        for (let i = 0; i < preDefinedGroups.length; i++) {
            firstRow = true;

            for (let j = 0; j < sortedResourceArray.length; j++) {
                if (sortedResourceArray[j].category == preDefinedGroups[i]) {

                    body_arr.push(
                        <ResourceRow
                            blockableDays={this.props.blockableDays}
                            selectedBlocker={this.props.selectedBlocker}
                            highlight={this.props.highlight}
                            alldayBlock={this.props.alldayBlock}
                            refreshCalendar={this.props.refreshCalendar}
                            errorMessage={this.props.errorMessage}
                            resource={sortedResourceArray[j]}
                            arr_ddMM={this.props.arr_ddMM}
                            planContent={this.props.planContent}
                            slimView={this.props.slimView}
                            currentUserId={this.props.currentUserId}
                            startDate={this.props.startDate}
                            editMode={this.props.editMode}
                            refreshBlockData={this.props.refreshBlockData}
                            selectedSubBlocker={this.props.selectedSubBlocker}
                            firstWeekDays={this.props.firstWeekDays}
                            choosenWeeks={this.props.choosenWeeks}
                            messageBar={this.props.messageBar}
                            theme={this.props.theme}
                            removeStructurepart={this.props.removeStructurepart}
                            editResource={this.props.editResource}
                            clientType={this.props.clientType}
                            firstRow={firstRow} />
                    );
                    
                    firstRow = false;
                }
            }
        }

        return (
            <tbody id={"ressourceTableBody"}>
                {body_arr}
            </tbody>
        );
    }

    public getGroups = ():string[] => {
        let groups:string[] = this.props.groups.split(",");
        groups = Array.from(new Set(groups));
        groups = groups.map((value)=>{return value.trim();});
        return groups.sort((a,b)=> {return a.localeCompare(b);});
    }
}