import * as React from 'react';
import { TooltipHost, TooltipDelay } from 'office-ui-fabric-react/lib/Tooltip';
import { IStructurepart } from '../interfaces/IStructurepart';
import { ITimeSlot } from '../interfaces/ITimeSlot';
import { AzureService } from '../services/AzureService';
import { IBlock } from '../interfaces/IBlock';
import { INewBlockData } from '../interfaces/INewBlockData';

interface Info {
    color: string;
    tooltip: string;
    initials: string;
    managerID: string;
    projectID: number;
}

export interface IResourceSlotProps {
    absent?: boolean;
    refreshCalendar?(): void;
    we?: boolean;
    blockWD?: boolean;
    alldayBlock?: boolean;
    blocked?: boolean;
    selectedSubBlocker?: IStructurepart;
    editMode?: boolean;
    userId?: string;
    highlight?: boolean;
    messageBar?(messageBar: JSX.Element): void;
    errorMessage?(message: JSX.Element): void;
    selectedBlocker?: IStructurepart;
    userHighlight?: { state: boolean, userID: string };
    timeSlots?: ITimeSlot[];
    refreshBlockData: Function;
    slimView: boolean;
    darkClassName: string;
}

export interface IResourceSlotState {
    infoAM: Info;
    infoPM: Info;
    preventOnClick: boolean;
    contextMenuOpen: boolean;
}

export interface IApiCallQueue {
    selectedSubBlocker: IStructurepart;
    slot: number;
    selectedBlocker: IStructurepart;
    date: Date;
    allDayBlock: boolean;
}

export class ResourceSlot extends React.Component<IResourceSlotProps, IResourceSlotState>{
    private _apiCallQueue: IApiCallQueue[] = [];

    constructor(props: IResourceSlotProps) {
        super(props);
        this.state = {
            infoAM: {
                color: "",
                initials: "",
                tooltip: "",
                managerID: "",
                projectID: -1
            },
            infoPM: {
                color: "",
                initials: "",
                tooltip: "",
                managerID: "",
                projectID: -1
            },
            preventOnClick: false,
            contextMenuOpen: false
        };
    }

    public render(): React.ReactElement {
        let elements = [];

        this.props.timeSlots.forEach((currentSlot: ITimeSlot) => {
            let tooltip = currentSlot.Blocker ? currentSlot.Blocker.initials : "";
            let color = currentSlot.Blocker ? currentSlot.Blocker.color : "";
            let slimView = this.props.slimView ? " slim" : "";
            elements.push(
                <td
                    className={(this.props.selectedBlocker != null ? "cellAssignment" : "cellAssignmentNotSelected") + slimView + this.props.darkClassName}
                    style={{ backgroundColor: color}}
                    onClick={() => this.state.preventOnClick ? this._addToQueue(currentSlot) : this._addOrEdit(currentSlot)}
                    onContextMenu={(event) => {
                        this.state.preventOnClick ? this._addToQueue(currentSlot) : this._delete(event, currentSlot);
                    }}>
                    <TooltipHost delay={TooltipDelay.long} content={tooltip} calloutProps={{ gapSpace: 0 }}>
                        <div className={this.props.editMode ? "delMe" : ""}>
                            {tooltip}
                        </div>
                    </TooltipHost>
                </td>
            );
        });

        return (
            <>
                {elements}
            </>
        );
    }

    private _checkAndProcessQueue = (): void => {
        if (this._apiCallQueue.length) {
            let processingItem: IApiCallQueue = this._apiCallQueue.shift();
            let timeSlot = this.props.timeSlots.find((value) => {
                if (value.Slot = processingItem.slot) {
                    return true;
                }
                return false;
            });
            this._addOrEdit(timeSlot);
        }
        else {
            this.setState({ preventOnClick: false });
        }
    }

    private _addOrEdit = (timeSlot: ITimeSlot) => {
        if (this.props.selectedBlocker != null) {
            if (timeSlot.Blocker) {

                timeSlot.Block.blockerId = this.props.selectedBlocker.id;
                AzureService.editBlock(timeSlot.Block).then(() => {
                    this.props.refreshBlockData();
                });
            } else {
                let block: INewBlockData = {
                    BlockerId: this.props.selectedBlocker.id,
                    PlanId: this.props.selectedBlocker.planId,
                    Description: "",
                    ResourceId: this.props.userId,
                    Start: timeSlot.Date,
                    TenantId: this.props.selectedBlocker.tenantId,
                    TimeSlot: timeSlot.Slot
                }

                AzureService.addBlock(block).then((newBlock: IBlock[]) => {
                    this.props.refreshBlockData();
                });
            }
        } else {
            console.log("Nichts ausgewählt!");
        }
        this._checkAndProcessQueue();
    }

    private _addToQueue = (timeSlot: ITimeSlot) => {
        if (this.props.selectedBlocker != null) {
            let addToQueue: boolean = false;
            if (timeSlot.Blocker.id != this.props.selectedBlocker.id) {
                //handle am
                for (let i = 0; i < this._apiCallQueue.length; i++) {
                    if (this._apiCallQueue[i].slot == timeSlot.Slot &&
                        this._apiCallQueue[i].date == timeSlot.Date &&
                        this._apiCallQueue[i].selectedBlocker.id == this.props.selectedBlocker.id) {
                        return;
                    }
                }
                addToQueue = true;
            }
            // else if (this.props.alldayBlock) {
            //     //handle all day block
            //     let idDifferent = false;
            //     for (let j = 0; j < this.props.timeSlots.length; j++) {
            //         if ((this.props.timeSlots[j].Blocker && (this.props.timeSlots[j].Blocker.id != this.props.selectedSubBlocker.id)) ||
            //             (!this.props.timeSlots[j].Blocker && this.props.selectedSubBlocker.id)) {
            //             idDifferent = true;
            //             break;
            //         }
            //     }

            //     if (idDifferent) {
            //         for (let i = 0; i < this._apiCallQueue.length; i++) {
            //             if (this._apiCallQueue[i].allDayBlock &&
            //                 this._apiCallQueue[i].date == timeSlot.Date &&
            //                 this._apiCallQueue[i].selectedSubBlocker.id == this.props.selectedSubBlocker.id) {
            //                 return;
            //             }
            //         }
            //         addToQueue = true;
            //     }
            // }

            if (addToQueue) {
                this._apiCallQueue.push({
                    allDayBlock: this.props.alldayBlock,
                    date: timeSlot.Date,
                    selectedBlocker: this.props.selectedBlocker,
                    selectedSubBlocker: this.props.selectedSubBlocker,
                    slot: timeSlot.Slot
                });
            }
        }
    }

    private _delete = async (event: React.MouseEvent<HTMLTableDataCellElement>, timeSlot: ITimeSlot) => {
        event.preventDefault();
        this.setState({
            preventOnClick: true
        });
        if (this.props.editMode) {
            if (event.button = 2) {
                if (this.props.alldayBlock) {
                    let blocks: IBlock[] = [];
                    this.props.timeSlots.forEach((currentSlot) => {
                        if (currentSlot.Block) {
                            blocks.push(currentSlot.Block);
                        }
                    });
                    await AzureService.deleteBlock(blocks);
                    this.props.refreshBlockData();
                }
                else {
                    await AzureService.deleteBlock([timeSlot.Block]);
                    this.props.refreshBlockData();
                }
            }
        }
        this.setState({ preventOnClick: false });
    }

}